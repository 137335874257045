










import { Component, Vue } from 'vue-property-decorator';
import DefaultPasswordRecoveryComplete from '@/components/organisms/default/password_recovery/DefaultPasswordRecoveryComplete.vue';
import PRS0000001PasswordRecoveryComplete from '@/components/organisms/PRS0000001/password_recovery/PRS0000001PasswordRecoveryComplete.vue';
import { AllowSettings } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultPasswordRecoveryComplete,
        PRS0000001PasswordRecoveryComplete,
        PRS0000001SubFooter
    }
})
export default class PasswordRecoveryComplete extends Vue {

    private provisionShopCode: string = "";
    private authKey: string = ""

    mounted () {
        this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')
        this.authKey = VueHelper.getParameter(this, 'auth_key')

        if (!AllowSettings.isUsePasswordRecovery(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
    }

}
