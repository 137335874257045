











import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultMailEditSend from '@/components/organisms/default/mail_edit/DefaultMailEditSend.vue';
import PRS0000001MailEditSend from '@/components/organisms/PRS0000001/mail_edit/PRS0000001MailEditSend.vue';
import { VueHelper } from '@/helpers/vue';
import { AllowSettings } from '@/types/converter/convert';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultMailEditSend,
        PRS0000001MailEditSend,
        PRS0000001SubFooter
    },
})
export default class MailEditSend extends Vue {

    private isLoading: boolean = true
    @Prop() private provisionShopCode! : string;
    
    mounted () {
        if (!AllowSettings.isUseMailEdit(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        this.isLoading = false
    }

}
