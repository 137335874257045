











import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultWithdrawComplete from '@/components/organisms/default/withdraw/DefaultWithdrawComplete.vue';
import PRS0000001WithdrawComplete from '@/components/organisms/PRS0000001/withdraw/PRS0000001WithdrawComplete.vue';
import { VueHelper } from '@/helpers/vue';
import { AllowSettings } from '@/types/converter/convert';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultWithdrawComplete,
        PRS0000001WithdrawComplete,
        PRS0000001SubFooter
    },
})
export default class WithdrawComplete extends Vue {

    private isLoading: boolean = true
    @Prop() private provisionShopCode! : string;

    mounted () {
        if (!AllowSettings.isUseWithdraw(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        this.isLoading = false
    }

}
