import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/api'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
Vue.use(api); 

Vue.config.productionTip = false
import "@/assets/stylus/global.styl"
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
