







import { Component, Vue } from 'vue-property-decorator';
import BtnBase from '@/components/atoms/BtnBase.vue';

@Component({
    components: {
        BtnBase
    }
})
export default class NotFound extends Vue {
    back () {
        history.go(-2)
    }
}
