import { KoseSiteConfig } from '@/types/types'
import { OCLSiteConfig, PRSiteConfig, OLCDecorteSiteConfig, OLCJillSiteConfig, OLCMaisonSiteConfig, env } from '@baseConfig'
import { Const } from '@/const/const'

export class Sex {
    private static _values = new Array<Sex>()

    public static FEMALE = new Sex('F', '女性')
    public static MALE = new Sex('M', '男性')
    public static NO_ANSWER = new Sex('N', '無回答')

    public constructor (
      public code: string,
      public value: string) {
        Sex._values.push(this)
    }

    public static get values (): Sex[] {
        return Sex._values
    }

    public static findValue (code: string): Sex | undefined {
        return Sex._values.find(sex => sex.code === code)
    }

    public static getSelectList() {
        return Sex._values.map(sex => {
            return {
                label: sex.value,
                val: sex.code
            }
        })
    }
}

export class HttpStatus {
    private static _values = new Array<HttpStatus>()

    public static OK = new HttpStatus(200)
    public static FOUND = new HttpStatus(302)
    public static BAD_REQUEST = new HttpStatus(400)
    public static UNAUTHORIZED = new HttpStatus(401)
    public static FORBIDDEN = new HttpStatus(403)
    public static NOT_FOUND = new HttpStatus(404)
    public static CONFLICT = new HttpStatus(409)
    public static INTERNAL_SERVER_ERROR = new HttpStatus(500)
    public static LOCKED = new HttpStatus(423)

    public constructor (
        public code: number) {
        HttpStatus._values.push(this)
    }
}

export class Prefectures {
    public static prefectures: string[] = [
        '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
        '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
        '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県',
        '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
        '鳥取県', '島根県', '岡山県', '広島県', '山口県',
        '徳島県', '香川県', '愛媛県', '高知県',
        '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
    ]

    public static getPrefectures () {
        return Prefectures.prefectures.map(prefecture => {
            return {
                label: prefecture,
                val: prefecture
            }
        })
    }

    public static getSelectList () {
        return [
            {
                label: '',
                val: ''
            }
        ].concat(Prefectures.getPrefectures())
    }
}

export class KoseIDSite {
    private static _values = new Array<KoseIDSite>()

    // define display order
    public static PR = new KoseIDSite(Const.PROVISION_SHOP_CODE_PR, 'PR', PRSiteConfig)

    public static OLC_DECORTE = new KoseIDSite(Const.PROVISION_SHOP_CODE_OLC_DECORTE, 'OLC(DECORTE)', OLCDecorteSiteConfig)
    public static OLC_DECORTE_KDEV1 = new KoseIDSite('OLC0000001KDEV1', 'OLC(DECORTE)DEV1', OLCDecorteSiteConfig)
    public static OLC_DECORTE_KDEV2 = new KoseIDSite('OLC0000001KDEV2', 'OLC(DECORTE)KDEV2', OLCDecorteSiteConfig)
    public static OLC_DECORTE_YDEV1 = new KoseIDSite('OLC0000001YDEV1', 'OLC(DECORTE)DEV1', OLCDecorteSiteConfig)
    public static OLC_DECORTE_YDEV2 = new KoseIDSite('OLC0000001YDEV2', 'OLC(DECORTE)DEV2', OLCDecorteSiteConfig)
    public static OLC_DECORTE_YDEV3 = new KoseIDSite('OLC0000001YDEV3', 'OLC(DECORTE)DEV3', OLCDecorteSiteConfig)
    public static OLC_DECORTE_EDU1 = new KoseIDSite('OLC0000001EDU1', 'OLC(DECORTE)EDU1', OLCDecorteSiteConfig)
    public static OLC_DECORTE_LOC1 = new KoseIDSite('OLC0000001LOC1', 'OLC(DECORTE)LOC1', OLCDecorteSiteConfig)

    public static OLC_JILL = new KoseIDSite(Const.PROVISION_SHOP_CODE_OLC_JILL, 'OLC(Jill)', OLCJillSiteConfig)
    public static OLC_JILL_KDEV1 = new KoseIDSite('OLC0000002KDEV1', 'OLC(Jill)KDEV1', OLCJillSiteConfig)
    public static OLC_JILL_YDEV1 = new KoseIDSite('OLC0000002YDEV1', 'OLC(Jill)YDEV1', OLCJillSiteConfig)
    public static OLC_JILL_LOC1 = new KoseIDSite('OLC0000002LOC1', 'OLC(Jill)LOC1', OLCJillSiteConfig)

    public static OLC_MAISON = new KoseIDSite(Const.PROVISION_SHOP_CODE_OLC_MAISON, 'OLC(Maison)', OLCMaisonSiteConfig)
    public static OLC_MAISON_KDEV1 = new KoseIDSite('OLC0000003KDEV1', 'OLC(Maison)', OLCMaisonSiteConfig)
    public static OLC_MAISON_YDEV1 = new KoseIDSite('OLC0000003YDEV1', 'OLC(Maison)', OLCMaisonSiteConfig)
    public static OLC_MAISON_LOC1 = new KoseIDSite('OLC0000003LOC1', 'OLC(Maison)', OLCMaisonSiteConfig)

    public static OLC_SEKKISEI = new KoseIDSite(Const.PROVISION_SHOP_CODE_OLC_SEKKISEI, 'OLC(Sekkisei)', OCLSiteConfig)
    public constructor (
      public code: string,
      public name: string,
      public config: KoseSiteConfig) {
        KoseIDSite._values.push(this)
    }

    public static get values (): KoseIDSite[] {
        return KoseIDSite._values
    }

    public static findValue (code: string): KoseIDSite | undefined {
        return KoseIDSite._values.find(site => site.code === code)
    }

    public static isOLCDecorteGroup (code: string): boolean {
        const site = KoseIDSite.findValue(code)
        return site == KoseIDSite.OLC_DECORTE || 
                site == KoseIDSite.OLC_DECORTE_KDEV1 ||
                site == KoseIDSite.OLC_DECORTE_KDEV2 ||
                site == KoseIDSite.OLC_DECORTE_YDEV1 ||
                site == KoseIDSite.OLC_DECORTE_YDEV2 ||
                site == KoseIDSite.OLC_DECORTE_YDEV3 ||
                site == KoseIDSite.OLC_DECORTE_EDU1 ||
                site == KoseIDSite.OLC_DECORTE_LOC1
    }

    public static isOLCJillGroup (code: string): boolean {
        const site = KoseIDSite.findValue(code)
        return site == KoseIDSite.OLC_JILL || 
                site == KoseIDSite.OLC_JILL_KDEV1 ||
                site == KoseIDSite.OLC_JILL_YDEV1 ||
                site == KoseIDSite.OLC_JILL_LOC1
    }

    public static isOLCMaisonGroup (code: string): boolean {
        const site = KoseIDSite.findValue(code)
        return site == KoseIDSite.OLC_MAISON || 
                site == KoseIDSite.OLC_MAISON_KDEV1 ||
                site == KoseIDSite.OLC_MAISON_YDEV1 ||
                site == KoseIDSite.OLC_MAISON_LOC1
    }

    // SSO連携をする、別タブでログイン後の画面を利用するサイト
    // トップ、マイページへのリンクは表示しない
    public static isAnotherTabSSOSite (code: string): boolean {
        return KoseIDSite.isOLCDecorteGroup(code) ||
                KoseIDSite.isOLCJillGroup(code) ||
                KoseIDSite.isOLCMaisonGroup(code)
    }

    // 別タブで開くサイト OLC4
    public static isAnotherTabSite (code: string): boolean {
        const site = KoseIDSite.findValue(code)
        return site == KoseIDSite.OLC_SEKKISEI
    }
}

export class Env {
    private static _values = new Array<Env>()

    public static PRD = new Env('production')
    public static STG = new Env('staging')
    public static DEV = new Env('development')
    public constructor (
        public env: string) {
        Env._values.push(this)
    }

    public static isPrd(): boolean {
        return env == Env.PRD.env
    }

    public static isStg(): boolean {
        return env == Env.STG.env
    }

    public static isDev(): boolean {
        return env == Env.DEV.env
    }
}

export class AllowSettings {
    public static ALL_ALLOW_GROUP = [KoseIDSite.PR.code, KoseIDSite.OLC_DECORTE.code, KoseIDSite.OLC_JILL.code, KoseIDSite.OLC_MAISON.code]
    public static NO_LOGIN_GROUP = [KoseIDSite.OLC_SEKKISEI.code]

    public static TEST_ALL_ALLOW_GROUP = Env.isPrd() ? [''] : [
        KoseIDSite.OLC_DECORTE_KDEV1.code,
        KoseIDSite.OLC_DECORTE_KDEV2.code,
        KoseIDSite.OLC_DECORTE_YDEV1.code,
        KoseIDSite.OLC_DECORTE_YDEV2.code,
        KoseIDSite.OLC_DECORTE_YDEV3.code,
        KoseIDSite.OLC_DECORTE_EDU1.code,
        KoseIDSite.OLC_DECORTE_LOC1.code,
        KoseIDSite.OLC_JILL_KDEV1.code,
        KoseIDSite.OLC_JILL_YDEV1.code,
        KoseIDSite.OLC_JILL_LOC1.code,
        KoseIDSite.OLC_MAISON_KDEV1.code,
        KoseIDSite.OLC_MAISON_YDEV1.code,
        KoseIDSite.OLC_MAISON_LOC1.code
    ]
    public static TEST_NO_LOGIN_GROUP = Env.isPrd() ? [''] : [
        // KoseIDSite.OLC_JILL_KDEV1.code,
        // KoseIDSite.OLC_JILL_YDEV1.code,
        // KoseIDSite.OLC_JILL_LOC1.code
    ]

    public static isUseSignup (code: string): boolean {
        return  AllowSettings.ALL_ALLOW_GROUP.includes(code) || AllowSettings.NO_LOGIN_GROUP.includes(code) || AllowSettings.TEST_ALL_ALLOW_GROUP.includes(code) || AllowSettings.TEST_NO_LOGIN_GROUP.includes(code)
    }

    public static isUsePasswordRecovery (code: string): boolean {
        return  AllowSettings.ALL_ALLOW_GROUP.includes(code) || AllowSettings.NO_LOGIN_GROUP.includes(code) || AllowSettings.TEST_ALL_ALLOW_GROUP.includes(code) || AllowSettings.TEST_NO_LOGIN_GROUP.includes(code)
    }

    public static isUseLogin (code: string): boolean {
        return  AllowSettings.ALL_ALLOW_GROUP.includes(code) || AllowSettings.TEST_ALL_ALLOW_GROUP.includes(code)
    }

    public static isUseEdit (code: string): boolean {
        return  AllowSettings.ALL_ALLOW_GROUP.includes(code) || AllowSettings.TEST_ALL_ALLOW_GROUP.includes(code)
    }

    public static isUseMailEdit (code: string): boolean {
        return  AllowSettings.ALL_ALLOW_GROUP.includes(code) || AllowSettings.TEST_ALL_ALLOW_GROUP.includes(code)
    }

    public static isUsePasswordEdit (code: string): boolean {
        return  AllowSettings.ALL_ALLOW_GROUP.includes(code) || AllowSettings.TEST_ALL_ALLOW_GROUP.includes(code)
    }

    public static isUseWithdraw (code: string): boolean {
        return  AllowSettings.ALL_ALLOW_GROUP.includes(code) || AllowSettings.TEST_ALL_ALLOW_GROUP.includes(code)
    }
}

export class LoginBackCode {
    private static _values = new Array<LoginBackCode>()

    public static EDIT = new LoginBackCode('edit')
    public static PASSWORD_EDIT = new LoginBackCode('password-edit')
    public static MAIL_EDIT = new LoginBackCode('mail-edit')
    public static WITHDRAW = new LoginBackCode('withdraw')

    public constructor (
        public code: string) {
        LoginBackCode._values.push(this)
    }
}

export class InputEventType {
    private static _values = new Array<InputEventType>()

    public static NONE = new InputEventType(0)
    public static ZENKAKU = new InputEventType(1)
    public static HANKAKU = new InputEventType(2)

    public constructor (
        public code: number) {
        InputEventType._values.push(this)
    }
}

export class Flag{
    private static _values = new Array<Flag>()

    public static ON = new Flag('1')
    public static OFF = new Flag('0')

    public constructor (
        public code: string) {
        Flag._values.push(this)
    }
}
