










import { Component, Vue } from 'vue-property-decorator';
import DefaultPasswordRecoveryEmailConfirmation from '@/components/organisms/default/password_recovery/DefaultPasswordRecoveryEmailConfirmation.vue';
import PRS0000001PasswordRecoveryEmailConfirmation from '@/components/organisms/PRS0000001/password_recovery/PRS0000001PasswordRecoveryEmailConfirmation.vue';
import { AllowSettings } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultPasswordRecoveryEmailConfirmation,
        PRS0000001PasswordRecoveryEmailConfirmation,
        PRS0000001SubFooter
    },
})
export default class PasswordRecoveryEmailConfirmation extends Vue {

    private provisionShopCode: string = "";
    private backUrlCode:string = ""
    private backUrlPrm:string = ""

    mounted () {
        this.backUrlCode = VueHelper.getParameter(this, 'back_url_code')
        this.backUrlPrm = VueHelper.getParameter(this, 'back_url_prm')
        this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')

        if (!AllowSettings.isUsePasswordRecovery(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
    }
}
