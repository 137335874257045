import { Module, Action, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store from '../store'
import { IUiState } from '@/types/store/types-ui'

@Module({ dynamic: true, store, name: 'ui' })
class Ui extends VuexModule implements IUiState {
    
    progress: boolean = false
   
    @Mutation
    private UPDATE_PROGRESS (value: boolean) {
        this.progress = value
    }

    @Action({ rawError: true })
    public async updateProgress (value: boolean) {
        this.UPDATE_PROGRESS(value)
        return value
    }

    public get isProgress () {
        return this.progress
    }
}

export const UiModule = getModule(Ui)
