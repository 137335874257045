











import { Component, Vue } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts
    },
})
export default class DefaultSignupComplete extends Vue {
    close () {
        window.close()
    }
}
