import { TypeInputParts, TypeSelectParts, TypeSelectBirthDayParts } from '@/types/components/types-parts'

export class ValidationHelper {
    public static requiredInputParts (input: TypeInputParts): boolean {
        if (!input.value) {
            input.error = input.label + 'が未入力です。'
            return false
        }
        input.error = ''
        return true
    }

    public static requiredSelectParts (input: TypeSelectParts): boolean {
        if (!input.value) {
            input.error = input.label + 'を指定してください。'
            return false
        }
        input.error = ''
        return true
    }

    public static requiredSelectBirthdayParts (input: TypeSelectBirthDayParts): boolean {
        if (!input.yyyy || !input.mm || !input.dd) {
            input.error = '生年月日が未入力です。'
            return false
        }
        input.error = ''
        return true
    }

    public static is_len_eq(value: string, len: number): boolean {
        if (value) {
            return value.length == len
        }
        return false
    }
}
