




































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { PutCustomerEdit } from '@/types/api/types-api';
import { StringHelper } from '@/helpers/string';
import { Sex, LoginBackCode } from '@/types/converter/convert';
import H2 from '@/components/atoms/H2.vue';
import { HttpStatus } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';

@Component({
    components: {
        Title,
        BtnBase,
        H2
    },
})
export default class DefaultEditConfirmation extends Vue {
    @Prop() private request!: PutCustomerEdit;
    @Prop() private email!: string;
    @Prop() private provisionShopCode!: string;

    get nameKanji () {
        return this.request.lastNameKanji + ' ' + this.request.firstNameKanji
    }

    get nameKana () {
        return this.request.lastNameKana + ' ' + this.request.firstNameKana
    }

    get zipCode () {
        return StringHelper.convertDisplayZipCode(this.request.zipCode)
    }

    get address () {
        return this.request.state + this.request.city + this.request.address1 + this.request.address2
    }

    get sex () {
        const sex = Sex.findValue(this.request.sex)
        return sex ? sex.value : ''
    }

    get birthday () {
        return StringHelper.convertDisplayDate(this.request.birthday)
    }

    back () {
        this.$emit('back')
    }

    async complete () {
        this.request.checkOnly = false
        VueHelper.lock()
        const response: any = await this.$api.putCustomerEdit(this.request)
        VueHelper.unlock()

        if (response.status === HttpStatus.OK.code) {
            VueHelper.transitionByLocation(this,{ path: `/edit/complete/${this.provisionShopCode}`})
        } else {
            ErrorHelper.apiErrorHandler(response, this, [], LoginBackCode.EDIT.code)
        }
    }
}
