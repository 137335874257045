










import { Component, Vue } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import DefaultEmailConfirmation from '@/components/organisms/default/signup/DefaultEmailConfirmation.vue';
import PRS0000001EmailConfirmation from '@/components/organisms/PRS0000001/signup/PRS0000001EmailConfirmation.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { AllowSettings } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts,
        DefaultEmailConfirmation,
        PRS0000001EmailConfirmation,
        PRS0000001SubFooter
    },
})
export default class EmailConfirmation extends Vue {

    private provisionShopCode: string = "";
    private backUrlCode:string = ""
    private backUrlPrm:string = ""

    mounted () {
        this.backUrlCode = VueHelper.getParameter(this, 'back_url_code')
        this.backUrlPrm = VueHelper.getParameter(this, 'back_url_prm')
        this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')

        if (!AllowSettings.isUseSignup(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
    }

}
