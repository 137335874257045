






import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class CheckBoxBase extends Vue {
    @Prop({ default: '' }) private id!: string;
    @Prop({ default: '' }) private label!: string;
    @Prop() private check!: boolean;

    set change(value: boolean) {
        this.$emit('change', value)
    }

    get change() {
        return this.check
    }

}

