










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
    @Prop({ default: '' }) private label!: string;

    close() {
        this.$emit('close')
    }
}
