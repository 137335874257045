







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Kanban from '@/components/atoms/Kanban.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { CustomerSignupRequest, GetAuthKeyRedirect, PostLoginRequest } from '@/types/api/types-api';
import { StringHelper } from '@/helpers/string';
import { Sex } from '@/types/converter/convert';
import H2 from '@/components/atoms/H2.vue';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';
import { HttpStatus } from '@/types/converter/convert';

@Component({
    components: {
        Kanban,
        BtnBase,
        H2
    },
})
export default class PRS0000001SignupConfirmation extends Vue {
    @Prop() private request!: CustomerSignupRequest;
    @Prop() private provisionShopCode!: string;

    get nameKanji () {
        return this.request.lastNameKanji + ' ' + this.request.firstNameKanji
    }

    get nameKana () {
        return this.request.lastNameKana + ' ' + this.request.firstNameKana
    }

    get zipCode () {
        return StringHelper.convertDisplayZipCode(this.request.zipCode)
    }

    get address () {
        return this.request.state + this.request.city + this.request.address1 + this.request.address2
    }

    get sex () {
        const sex = Sex.findValue(this.request.sex)
        return sex ? sex.value : ''
    }

    get birthday () {
        return StringHelper.convertDisplayDate(this.request.birthday)
    }

    get mailMagazine () {
        return this.request.dataBySite['mail_delivery_flag'] ? '希望する' : '希望しない'
    }

    back () {
        this.$emit('back')
    }

    async signup () {
        this.request.checkOnly = false
        VueHelper.lock()
        const response: any = await this.$api.postCustomerSignup(this.request)
        VueHelper.unlock()

        const apiResult = ErrorHelper.apiErrorHandler(response, this)
        if (apiResult) {
            // Do Redirect Login Get Login Prm
            const request_redirect: GetAuthKeyRedirect = new GetAuthKeyRedirect(
                this.provisionShopCode,
                this.request.authkey
            )
            const response_redirect: any = await this.$api.getAuthKeyRedirect(request_redirect);
            if (response_redirect.status === HttpStatus.FOUND.code) {
                const back_url_code = response_redirect.data.back_code
                const back_url_prm = response_redirect.data.back_prm
                const request_login = new PostLoginRequest (this.request.email, this.request.password, this.provisionShopCode, true, back_url_code, back_url_prm, true)
                // Call API
                const response_login: any = await this.$api.postLogin(request_login);
                // API Resuponse Handle
                if (!ErrorHelper.apiErrorHandler(
                    response_login,
                    this
                )) {
                    VueHelper.move(this, '/error')
                }
            } else {
                VueHelper.move(this, '/signup/complete?provision_shop_code=' + this.provisionShopCode)
            }
        } else {
            VueHelper.move(this, '/error')
        }
    }
}
