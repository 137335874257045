































import { Component, Vue, Prop } from 'vue-property-decorator';
import Title from '@/components/atoms/Title.vue';

import BtnBase from '@/components/atoms/BtnBase.vue';
import TriangleLink from '@/components/atoms/TriangleLink.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import InputParts from '@/components/molecules/InputParts.vue';
import SelectBirthDayParts from '@/components/molecules/SelectBirthDayParts.vue';
import SelectParts from '@/components/molecules/SelectParts.vue';
import Modal from '@/components/atoms/Modal.vue';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { HttpStatus, InputEventType, LoginBackCode } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';
import { ValidationHelper } from '@/helpers/validate';
import { PutCustomerWithdraw } from '@/types/api/types-api';


@Component({
    components: {
        Title,
        BtnBase,
        H2,
        InputBase,
        InputParts,
        TriangleLink,
        SelectBirthDayParts,
        SelectParts,
        Modal
    },
})
export default class DefaultWithdrawInput extends Vue {

    @Prop() private provisionShopCode!: string;
    isCheck: boolean = true
    isError: boolean = false
    isConfirm: boolean = false
    isAlreadyWithrdaw:boolean = false
    isOpenConfirmModal: boolean = false

    // Configure Input
    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "(例)xxxxx@xxxxx.xxxxx", 100)
    inputPartsPassword: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, true, "", 20, undefined, "", "password")
    
    // Check Validation
    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsEmail),
            ValidationHelper.requiredInputParts(this.inputPartsPassword),
        ]
        return checkList.every(value => value === true)
    }

    // Action signin
    async confirm() {
        // Validation Check
        this.isError = false
        this.isAlreadyWithrdaw = false
        this.isOpenConfirmModal = false
        this.isCheck = this.beforeCheck()
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }
        // Make Request
        const request = new PutCustomerWithdraw (this.inputPartsEmail.value, this.inputPartsPassword.value, this.provisionShopCode)
        // Call API
        VueHelper.lock()
        const response: any = await this.$api.putCustomerWithdraw(request);
        VueHelper.unlock()
        if (response.status === HttpStatus.OK.code) {
            VueHelper.moveWithDraw(this, this.provisionShopCode)
        }
        else {
            if (response.status === HttpStatus.NOT_FOUND.code) {
                this.isAlreadyWithrdaw = true
            }
            else if (response.status === HttpStatus.BAD_REQUEST.code) {
                this.isError = true
            }
            VueHelper.scrollTop()
            ErrorHelper.apiErrorHandler(
                response,
                this,
                [
                    this.inputPartsEmail,
                    this.inputPartsPassword,
                ],
                LoginBackCode.WITHDRAW.code
            )
        }
        
    }

    back () {
        VueHelper.scrollTop()
        this.isConfirm = false
    }

    close() {
        this.isOpenConfirmModal = false
    }

    open() {
        this.isError = false
        this.isCheck = this.beforeCheck()
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }
        
        VueHelper.scrollTop()
        this.isConfirm = true
        this.isOpenConfirmModal = true
    }
    
}
