













import { Component, Vue } from 'vue-property-decorator'
import ProgressCircular from '@/components/atoms/ProgressCircular.vue';
import { UiModule } from '@/store/ui'

@Component({
    components: {
        ProgressCircular
    }
})
export default class DefaultLayout extends Vue {
    get isProgress () {
        return UiModule.isProgress
    }
}

