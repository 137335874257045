import { Vue } from 'vue-property-decorator';
import * as typesAPI from '@/types/api/types-api'
import { HttpStatus, Flag } from '@/types/converter/convert'
import { TypeParts } from '@/types/components/types-parts'
import { Const } from '@/const/const';
import { VueHelper } from '@/helpers/vue';

export class ErrorHelper {
    public static apiErrorHandler (response: typesAPI.APIResponse, v: Vue, parts: TypeParts[] = [], loginBackCode:string|null = null): boolean {
        if (response.status == HttpStatus.OK.code) {
            return true
        }

        if (response.status == HttpStatus.FOUND.code) {
            // Responseに戻り先URL(return_toが存在する場合リダイレクト)
            if (response.data.return_to) {
                window.location = response.data.return_to
            }
            return true
        }
        if (response.status == HttpStatus.BAD_REQUEST.code) {
            if (response.data.type == Const.KEY_RESPONSE_BAD_REQUEST_TYPE_AUTH_KEY) {
                return false
            } else if (response.data.type == Const.KEY_RESPONSE_BAD_REQUEST_TYPE_VALIDATE) {
                // clear error message
                parts.forEach(parts => parts.error = "")

                const errors: any[] = response.data.validate_result
                errors.forEach(error => {
                    // console.log(error)
                    for (const key in error) {
                        const value = error[key]
                        const input: TypeParts | undefined = parts.find(parts => parts.name == key)
                        console.log(error)
                        if (input) {
                            if (value == 'required') {
                                input.error = input.label + 'が未入力です。'
                            } else if (value == 'match') {
                                input.error = input.label + 'に誤りがあります。'
                            } else if (value == 'email_format') {
                                input.error = 'メールアドレスの形式で入力してください。'
                            } else if (value.startsWith('length_max')) {
                                input.error =input.label + 'は' + error['length'] + '文字以内で入力してください。'
                            } else if (value.startsWith('length_min')) {
                                input.error = input.label + 'は' + error['length'] + '文字以上で入力してください。'
                            } else if (value.startsWith('length_equal')) {
                                input.error = input.label + 'は' + error['length'] + '文字で入力してください。'
                            } else if (value == 'password_format') {
                                input.error =  '入力されたパスワードに使用できない文字が含まれています。'
                            } else if (value == 'password_same_char3') {
                                input.error =  '入力されたパスワードが同一文字の連続などの不正な形式です。'
                            } else if (value == 'password_use_email') {
                                input.error =  'メールアドレスは使用できません。'
                            } else if (value == 'all_full_width') {
                                input.error =  input.label + 'は' + '全角文字で入力してください。'
                            } else if (value == 'all_full_width_kana') {
                                input.error =  input.label + 'は' + '全角カタカナで入力してください。'
                            } else if (value == 'date_format') {
                                input.error =  '生年月日に誤りがあります。'
                            } else if (value == 'validate_date_future') {
                                input.error =  '生年月日に正しい日付を指定してください。'
                            } else if (value == 'number_format') {
                                input.error =  input.label + 'は' + '数値で入力してください。'
                            } else if (value == 'sex_type') {
                                input.error =  '性別の選択が不正です。'
                            } else if (value == 'state_type') {
                                input.error =  '都道府県の選択が不正です。'
                            }
                        } else {
                            v.$router.push('/error')
                        }
                        break;
                    }
                })
            }
            return false
        }
        if (response.status == HttpStatus.FORBIDDEN.code) {
            if (loginBackCode) {
                VueHelper.moveByLocation(v,{ path: '/login', query: { provision_shop_code: v.$props.provisionShopCode, back_url_code: loginBackCode, auto: Flag.ON.code }})
                return false
            } else {
                VueHelper.moveByLocation(v,{ path: '/login', query: { provision_shop_code: v.$props.provisionShopCode, auto: Flag.ON.code }})
                return false
            }
        }
        if (response.status == HttpStatus.UNAUTHORIZED.code) {
            if (loginBackCode) {
                VueHelper.moveByLocation(v,{ path: '/login', query: { provision_shop_code: v.$props.provisionShopCode, back_url_code: loginBackCode, auto: Flag.OFF.code }})
                return false
            } else {
                VueHelper.moveByLocation(v,{ path: '/login', query: { provision_shop_code: v.$props.provisionShopCode, auto: Flag.OFF.code }})
                return false
            }
        }

        if (response.status == HttpStatus.NOT_FOUND.code ||
            response.status == HttpStatus.CONFLICT.code) {
            return false
        }
        // それ以外のエラーはエラーページへ遷移
        v.$router.push('/error')
        return false
    }
}
