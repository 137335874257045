





















import { Component, Vue, Prop } from 'vue-property-decorator';
import Kanban from '@/components/atoms/Kanban.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import TriangleLink from '@/components/atoms/TriangleLink.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import InputParts from '@/components/molecules/InputParts.vue';
import SelectBirthDayParts from '@/components/molecules/SelectBirthDayParts.vue';
import SelectParts from '@/components/molecules/SelectParts.vue';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { HttpStatus, InputEventType, LoginBackCode } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';
import { ValidationHelper } from '@/helpers/validate';
import { PostCustomerMailEdit } from '@/types/api/types-api';
import { Const } from '@/const/const';

@Component({
    components: {
        Kanban,
        BtnBase,
        H2,
        InputBase,
        InputParts,
        TriangleLink,
        SelectBirthDayParts,
        SelectParts
    },
})
export default class PRS0000001MailEditInput extends Vue {

    @Prop() private provisionShopCode!: string;
    isCheck: boolean = true

    // Configure Input
    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "(例)xxxxx@xxxxx.xxxxx", 100)

    // Check Validation
    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsEmail),
        ]
        return checkList.every(value => value === true)
    }

    // Action signin
    async confirm() {
        // Validation Check
        this.isCheck = this.beforeCheck()
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }
        // Make Request
        const request = new PostCustomerMailEdit (this.inputPartsEmail.value, this.provisionShopCode)
        // Call API
        VueHelper.lock()
        const response: any = await this.$api.postCustomerMailEdit(request);
        VueHelper.unlock()
        if (response.status === HttpStatus.OK.code) {
            VueHelper.transitionByLocation(this,{ path: `/mail-edit/send/${this.provisionShopCode}`})
        }
        else {
            this.isCheck = false
            VueHelper.scrollTop()
            if (response.status === HttpStatus.CONFLICT.code) {
                this.inputPartsEmail.error = Const.ERROR_MESSAGE_EXIST_EMAIL
                return
            } else {
                ErrorHelper.apiErrorHandler(
                    response,
                    this,
                    [
                        this.inputPartsEmail
                    ],
                    LoginBackCode.MAIL_EDIT.code
                )
            }
        }
    }
    
    // Action Press Back Button
    backToppage() {
        VueHelper.historyBack(this)
    }

    back () {
        VueHelper.scrollTop()
    }
    
}
