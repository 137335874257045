





























import { Component, Vue, Prop } from 'vue-property-decorator';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import TriangleLink from '@/components/atoms/TriangleLink.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import InputParts from '@/components/molecules/InputParts.vue';
import SelectBirthDayParts from '@/components/molecules/SelectBirthDayParts.vue';
import SelectParts from '@/components/molecules/SelectParts.vue';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { HttpStatus, InputEventType, LoginBackCode, KoseIDSite } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';
import { ValidationHelper } from '@/helpers/validate';
import { PutCustomerPasswordEdit } from '@/types/api/types-api';

@Component({
    components: {
        Title,
        BtnBase,
        H2,
        InputBase,
        InputParts,
        TriangleLink,
        SelectBirthDayParts,
        SelectParts
    },
})
export default class DefaultPasswordEditInput extends Vue {

    @Prop() private provisionShopCode!: string;
    isCheck: boolean = true
    isError: boolean = false
    isConfirm: boolean = false

    // Configure Input
    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "(例)xxxxx@xxxxx.xxxxx", 100)
    inputPartsPasswordOld: TypeInputParts = PartsHelper.createInputParts("現在のパスワード", "old_password", InputEventType.NONE, true, "", 20, undefined, "", "password")
    inputPartsPassword1: TypeInputParts = PartsHelper.createInputParts("新しいパスワード", "new_password", InputEventType.NONE, true, "", 20, undefined, ["・半角英数字記号6桁以上20桁以下で設定ください。","・IDと同様のパスワードは入力できません。","・同じ文字の3連続は不可となります。"], "password")
    inputPartsPassword2: TypeInputParts = PartsHelper.createInputParts("新しいパスワード（確認）", "new_password_2", InputEventType.NONE, true, "", 20, undefined, "（確認のためもう一度入力して下さい）", "password")

    // Check Validation
    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsEmail),
            ValidationHelper.requiredInputParts(this.inputPartsPasswordOld),
            ValidationHelper.requiredInputParts(this.inputPartsPassword1),
            ValidationHelper.requiredInputParts(this.inputPartsPassword2),
            this.checkSamePassword(this.inputPartsPassword1, this.inputPartsPassword2),
        ]
        return checkList.every(value => value === true)
    }

    // Action signin
    async confirm() {
        // Validation Check
        this.isError = false
        this.isCheck = this.beforeCheck()
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }
        // Make Request
        const request = new PutCustomerPasswordEdit (this.inputPartsEmail.value, this.inputPartsPasswordOld.value, this.inputPartsPassword1.value, this.provisionShopCode)
        // Call API
        VueHelper.lock()
        const response: any = await this.$api.putCustomerPasswordEdit(request);
        if (response.status === HttpStatus.OK.code) {
            VueHelper.transitionByLocation(this,{ path: `/password-edit/complete/${this.provisionShopCode}`})
        }
        else {
            this.isCheck = false
            VueHelper.scrollTop()
            if (response.status === HttpStatus.CONFLICT.code) {
                this.isError = true
            }
            else {
                ErrorHelper.apiErrorHandler(
                    response,
                    this,
                    [
                        this.inputPartsEmail,
                        this.inputPartsPasswordOld,
                        this.inputPartsPassword1,
                        this.inputPartsPassword2,
                    ],
                    LoginBackCode.PASSWORD_EDIT.code
                )
            }
        }
        VueHelper.unlock() 
    }
    
    // Action Press Back Button
    back () {
        VueHelper.historyBack(this)
    }

    checkSamePassword (password1: TypeInputParts, password2: TypeInputParts) {
        // 既にエラーがある場合return
        if (password1.error) {
            return true
        }
        const check: boolean = password1.value === password2.value
        if (!check) {
            password1.error = '入力されたパスワードと確認用パスワードが一致しません'
        }
        return check
    }

    get isAnotherTabSSOSite (): boolean {
        return KoseIDSite.isAnotherTabSSOSite(this.provisionShopCode)
    }
    
}
