import { Flag } from '@/types/converter/convert'

export class StringHelper {
    static ZENKAKU_MAP: any = {
        'a':'ａ',
        'b':'ｂ',
        'c':'ｃ',
        'd':'ｄ',
        'e':'ｅ',
        'f':'ｆ',
        'g':'ｇ',
        'h':'ｈ',
        'i':'ｉ',
        'j':'ｊ',
        'k':'ｋ',
        'l':'ｌ',
        'm':'ｍ',
        'n':'ｎ',
        'o':'ｏ',
        'p':'ｐ',
        'q':'ｑ',
        'r':'ｒ',
        's':'ｓ',
        't':'ｔ',
        'u':'ｕ',
        'v':'ｖ',
        'w':'ｗ',
        'x':'ｘ',
        'y':'ｙ',
        'z':'ｚ',
        'A':'Ａ',
        'B':'Ｂ',
        'C':'Ｃ',
        'D':'Ｄ',
        'E':'Ｅ',
        'F':'Ｆ',
        'G':'Ｇ',
        'H':'Ｈ',
        'I':'Ｉ',
        'J':'Ｊ',
        'K':'Ｋ',
        'L':'Ｌ',
        'M':'Ｍ',
        'N':'Ｎ',
        'O':'Ｏ',
        'P':'Ｐ',
        'Q':'Ｑ',
        'R':'Ｒ',
        'S':'Ｓ',
        'T':'Ｔ',
        'U':'Ｕ',
        'V':'Ｖ',
        'W':'Ｗ',
        'X':'Ｘ',
        'Y':'Ｙ',
        'Z':'Ｚ',
        '0':'０',
        '1':'１',
        '2':'２',
        '3':'３',
        '4':'４',
        '5':'５',
        '6':'６',
        '7':'７',
        '8':'８',
        '9':'９',
        '!':'！',
        '@':'＠',
        '#':'＃',
        '$':'＄',
        '%':'％',
        '^':'＾',
        '&':'＆',
        '*':'＊',
        '(':'（',
        ')':'）',
        '_':'＿',
        '+':'＋',
        '|':'｜',
        '~':'￣',
        '-':'－',
        '=':'＝',
        '\\':'￥',
        '`':'｀',
        '{':'｛',
        '}':'｝',
        '[':'［',
        ']':'］',
        ':':'：',
        '"':'”',
        ';':'；',
        '\'':'’',
        '<':'＜',
        '>':'＞',
        '?':'？',
        ',':'，',
        '.':'．',
        '/':'／',
        '｡':'。',
        '｢':'「',
        '｣':'」',
        '､':'、',
        '･':'・',
        'ｦ':'ヲ',
        'ｧ':'ァ',
        'ｨ':'ィ',
        'ｩ':'ゥ',
        'ｪ':'ェ',
        'ｫ':'ォ',
        'ｬ':'ャ',
        'ｭ':'ュ',
        'ｮ':'ョ',
        'ｯ':'ッ',
        'ｰ':'ー',
        'ｱ':'ア',
        'ｲ':'イ',
        'ｳ':'ウ',
        'ｴ':'エ',
        'ｵ':'オ',
        'ｶ':'カ',
        'ｷ':'キ',
        'ｸ':'ク',
        'ｹ':'ケ',
        'ｺ':'コ',
        'ｻ':'サ',
        'ｼ':'シ',
        'ｽ':'ス',
        'ｾ':'セ',
        'ｿ':'ソ',
        'ﾀ':'タ',
        'ﾁ':'チ',
        'ﾂ':'ツ',
        'ﾃ':'テ',
        'ﾄ':'ト',
        'ﾅ':'ナ',
        'ﾆ':'ニ',
        'ﾇ':'ヌ',
        'ﾈ':'ネ',
        'ﾉ':'ノ',
        'ﾊ':'ハ',
        'ﾋ':'ヒ',
        'ﾌ':'フ',
        'ﾍ':'ヘ',
        'ﾎ':'ホ',
        'ﾏ':'マ',
        'ﾐ':'ミ',
        'ﾑ':'ム',
        'ﾒ':'メ',
        'ﾓ':'モ',
        'ﾔ':'ヤ',
        'ﾕ':'ユ',
        'ﾖ':'ヨ',
        'ﾗ':'ラ',
        'ﾘ':'リ',
        'ﾙ':'ル',
        'ﾚ':'レ',
        'ﾛ':'ロ',
        'ﾜ':'ワ',
        'ﾝ':'ン',
        'ｶﾞ':'ガ',
        'ｷﾞ':'ギ',
        'ｸﾞ':'グ',
        'ｹﾞ':'ゲ',
        'ｺﾞ':'ゴ',
        'ｻﾞ':'ザ',
        'ｼﾞ':'ジ',
        'ｽﾞ':'ズ',
        'ｾﾞ':'ゼ',
        'ｿﾞ':'ゾ',
        'ﾀﾞ':'ダ',
        'ﾁﾞ':'ヂ',
        'ﾂﾞ':'ヅ',
        'ﾃﾞ':'デ',
        'ﾄﾞ':'ド',
        'ﾊﾞ':'バ',
        'ﾊﾟ':'パ',
        'ﾋﾞ':'ビ',
        'ﾋﾟ':'ピ',
        'ﾌﾞ':'ブ',
        'ﾌﾟ':'プ',
        'ﾍﾞ':'ベ',
        'ﾍﾟ':'ペ',
        'ﾎﾞ':'ボ',
        'ﾎﾟ':'ポ',
        'ｳﾞ':'ヴ',
        'ﾞ':'゛',
        'ﾟ':'゜',
        ' ':'　'
    };
    public static convertDisplayZipCode (zipCode: string): string {

        if (zipCode.length != 7) {
            return zipCode
        }
        return '〒' + zipCode.substr(0, 3) + ' - ' + zipCode.substr(3, 4)
    }

    public static convertDisplayDate (yyyyMMdd: string): string {
        if (yyyyMMdd.length != 8) {
            return yyyyMMdd
        }
        return yyyyMMdd.substr(0, 4) + '年'
         + yyyyMMdd.substr(4, 2) + '月' 
         + yyyyMMdd.substr(6, 2) + '日'
    }

    public static convertZenkaku(value: string): string {
        let result = ''
        for (let i = 0; i < value.length; ++i) {
            const c = value.charAt(i)
            // console.log(c)
            const zenkaku = StringHelper.ZENKAKU_MAP[c]
            if (zenkaku) {
                result += zenkaku
            } else {
                result += c
            }
        }
        return result
    }

    public static convertHankaku2Zenkaku(str: string) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }

    public static convertStrToBoolean(str: string): boolean {
        return str == Flag.ON.code
    }
}
