// request
abstract class APIRequestBase {
    mock: boolean = false
}

export class CustomerEmailConfirmationRequest extends APIRequestBase {
    email: string
    provisionShopCode: string
    backUrlCode:string
    backUrlPrm:string
    constructor (email: string,
        provisionShopCode: string,
        backUrlCode:string,
        backUrlPrm:string
    ) {
        super()
        this.email = email
        this.provisionShopCode = provisionShopCode
        this.backUrlCode = backUrlCode
        this.backUrlPrm = backUrlPrm
    }
}

export class CustomerMailAuthRequest extends APIRequestBase {
    authKey: string
    constructor (authKey: string) {
        super()
        this.authKey = authKey
    }
}

export class CustomerSignupRequest extends APIRequestBase {
    authkey: string
    email: string
    password: string
    shopCode: string
    lastNameKanji: string
    firstNameKanji: string
    lastNameKana: string
    firstNameKana: string
    tel: string
    zipCode: string
    state: string
    city: string
    address1: string
    address2: string
    sex: string
    birthday: string
    checkOnly: boolean
    dataBySite: {[key: string]: Object}
    constructor (
        authkey: string,
        email: string,
        password: string,
        shopCode: string,
        lastNameKanji: string,
        firstNameKanji: string,
        lastNameKana: string,
        firstNameKana: string,
        tel: string,
        zipCode: string,
        state: string,
        city: string,
        address1: string,
        address2: string,
        sex: string,
        birthday: string,
        checkOnly: boolean,
        dataBySite: {[key: string]: Object} = {}) {
        super()
        this.authkey = authkey
        this.email = email
        this.password = password
        this.shopCode = shopCode
        this.lastNameKanji = lastNameKanji
        this.firstNameKanji = firstNameKanji
        this.lastNameKana = lastNameKana
        this.firstNameKana = firstNameKana
        this.tel = tel
        this.zipCode = zipCode
        this.state = state
        this.city = city
        this.address1 = address1
        this.address2 = address2
        this.sex = sex
        this.birthday = birthday
        this.checkOnly = checkOnly
        this.dataBySite = dataBySite
    }
}

export class PostCustomerPasswordMailAuthRequest extends APIRequestBase {
    email: string
    tel: string
    provisionShopCode: string
    backUrlCode:string
    backUrlPrm:string
    constructor (email: string,
        tel: string,
        provisionShopCode: string,
        backUrlCode:string,
        backUrlPrm:string
    ) {
        super()
        this.email = email
        this.tel = tel
        this.provisionShopCode = provisionShopCode
        this.backUrlCode = backUrlCode
        this.backUrlPrm = backUrlPrm
    }
}

export class GetCustomerPasswordMailAuthRequest extends APIRequestBase {
    authKey: string
    constructor (authKey: string) {
        super()
        this.authKey = authKey
    }
}

export class PostCustomerPasswordRecoveryRequest extends APIRequestBase {
    authKey: string
    email: string
    password: string
    constructor (authKey: string,
        email: string,
        password: string) {
        super()
        this.authKey = authKey
        this.email = email
        this.password = password
    }
}

export class PostLoginRequest extends APIRequestBase {
    email: string
    password: string
    provisionShopCode: string
    checked:boolean
    backUrlCode:string
    backUrlPrm:string
    isNewRegistrationBy: boolean = false
    dataBySite: {[key: string]: Object}
    constructor (email: string,
        password: string,
        provisionShopCode: string,
        checked: boolean,
        backUrlCode:string,
        backUrlPrm:string,
        isNewRegistrationBy: boolean,
        dataBySite: {[key: string]: Object} = {}) {
        super()
        this.email = email
        this.password = password
        this.provisionShopCode = provisionShopCode
        this.checked = checked
        this.backUrlCode = backUrlCode
        this.backUrlPrm = backUrlPrm
        this.isNewRegistrationBy = isNewRegistrationBy
        this.dataBySite = dataBySite
    }
}

export class PutCustomerEdit extends APIRequestBase {
    shopCode: string
    lastNameKanji: string
    firstNameKanji: string
    lastNameKana: string
    firstNameKana: string
    tel: string
    zipCode: string
    state: string
    city: string
    address1: string
    address2: string
    sex: string
    birthday: string
    checkOnly: boolean
    provisionShopCode: string

    constructor (
        shopCode: string,
        lastNameKanji: string,
        firstNameKanji: string,
        lastNameKana: string,
        firstNameKana: string,
        tel: string,
        zipCode: string,
        state: string,
        city: string,
        address1: string,
        address2: string,
        sex: string,
        birthday: string,
        checkOnly: boolean,
        provisionShopCode: string) {
        super()
        this.shopCode = shopCode
        this.lastNameKanji = lastNameKanji
        this.firstNameKanji = firstNameKanji
        this.lastNameKana = lastNameKana
        this.firstNameKana = firstNameKana
        this.tel = tel
        this.zipCode = zipCode
        this.state = state
        this.city = city
        this.address1 = address1
        this.address2 = address2
        this.sex = sex
        this.birthday = birthday
        this.checkOnly = checkOnly
        this.provisionShopCode = provisionShopCode
    }
}

export class PostCustomerMailEdit extends APIRequestBase {
    email: string
    provisionShopCode: string
    constructor (email: string, provisionShopCode: string) {
        super()
        this.email = email
        this.provisionShopCode = provisionShopCode
    }
}

export class PutCustomerMailEdit extends APIRequestBase {
    authKey: string
    provisionShopCode: string
    constructor (authKey: string, provisionShopCode: string) {
        super()
        this.authKey = authKey
        this.provisionShopCode = provisionShopCode
    }
}

export class PutCustomerPasswordEdit extends APIRequestBase {
    email: string
    oldPassword: string
    newPassword: string
    provisionShopCode: string
    constructor (email: string, oldPassword: string, newPassword:string, provisionShopCode: string) {
        super()
        this.email = email
        this.oldPassword = oldPassword
        this.newPassword = newPassword
        this.provisionShopCode = provisionShopCode
    }
}

export class PutCustomerWithdraw extends APIRequestBase {
    email: string
    password: string
    provisionShopCode: string
    constructor (email: string, password: string, provisionShopCode: string) {
        super()
        this.email = email
        this.password = password
        this.provisionShopCode = provisionShopCode
    }
}

export class GetCustomerEditRequest extends APIRequestBase {
    provisionShopCode: string
    constructor (provisionShopCode: string) {
        super()
        this.provisionShopCode = provisionShopCode
    }
}

export class GetAuthKeyRedirect extends APIRequestBase {
    provisionShopCode: string
    authKey: string
    constructor (provisionShopCode: string, authKey: string) {
        super()
        this.provisionShopCode = provisionShopCode
        this.authKey = authKey
    }
}

export class PostOAuthSetupRequest extends APIRequestBase {
    provisionShopCode: string
    backUrlCode: string
    backUrlPrm: string
    constructor (provisionShopCode: string, backUrlCode: string, backUrlPrm: string) {
        super()
        this.provisionShopCode = provisionShopCode
        this.backUrlCode = backUrlCode
        this.backUrlPrm = backUrlPrm
    }
}

// response
abstract class APIResponseBase {
    data?: any
    status?: number
    header?: any
}

export class APIResponse extends APIResponseBase {}
