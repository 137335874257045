




import { Component, Prop, Vue } from 'vue-property-decorator'
import { StringHelper } from '@/helpers/string';
import { InputEventType } from '@/types/converter/convert';
@Component
export default class InputBase extends Vue {
    @Prop({ default: '' }) private name!: string;

    @Prop({ default: '' }) private placeholder!: string;

    @Prop({ default: '' }) private value!: string;

    @Prop({ default: 'text' }) private type!: string;

    @Prop({ default: 200 }) private maxLength!: number;

    @Prop() private size!: number;

    @Prop({ default: false }) private isDisabled!: boolean;

    @Prop() private inputEventType!: InputEventType;

    private isFull: boolean = false;

    created () {
        if (!this.size) {
            this.isFull = true
        }
    }

    blur (value: string) {
        if (this.type === 'password') {
            return
        } 
        if (value) {
            value = value.trim()
        }
        if (this.inputEventType.code === InputEventType.ZENKAKU.code) {
            value = StringHelper.convertZenkaku(value)
        }
        if (this.inputEventType.code === InputEventType.HANKAKU.code) {
            value = StringHelper.convertHankaku2Zenkaku(value)
        }
        this.$emit('inputValue', value)
    }

    change (value: string) {
        this.$emit('change', value)
    }

    set inputValue (value: string) {
        this.$emit('inputValue', value)
    }

    get inputValue () {
        return this.value
    }
}
