










































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import TriangleLink from '@/components/atoms/TriangleLink.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import InputParts from '@/components/molecules/InputParts.vue';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { HttpStatus, InputEventType } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';
import { ValidationHelper } from '@/helpers/validate';
import { PostLoginRequest } from '@/types/api/types-api';
import CheckBoxBase from '@/components/atoms/CheckBoxBase.vue';
import AgreementModal from '@/components/molecules/modal/olc_maison/OLCMaisonKoseIdAgreementModal.vue';
import AgreementServiceModal from '@/components/molecules/modal/olc_maison/OLCMaisonServiceAgreementModal.vue';
import PrivacyPolicyModal from '@/components/molecules/modal/PrivacyPolicyModal.vue';

@Component({
    components: {
        Title,
        BtnBase,
        H2,
        InputBase,
        InputParts,
        TriangleLink,
        CheckBoxBase,
        AgreementModal,
        AgreementServiceModal,
        PrivacyPolicyModal
    },
})
export default class OLC0000003Login extends Vue {
    @Prop() private provisionShopCode!: string;
    @Prop() private backUrlCode!: string;
    @Prop() private backUrlPrm!: string;
    isCheck: boolean = true
    isError: boolean = false
    isAgreeCheck: boolean = false
    isAgreeCheckBefore: boolean = true
    // Configure Input Email
    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, false, "(例)xxxxx@xxxxx.xxxxx", 100, undefined, "", "text")
    // Configure Input Password
    inputPartsPassword: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, false, "", 20, undefined, "", "password")

    // HACK 必要であれば後にパーツ化
    needAgreementCheck: boolean = false
    ng_agreements: Array<number> = []

    agreementCheck: boolean = false
    agreementCheckError: string = ""
    isOpenAgreementModal: boolean = false

    needAgreementBySiteCheck: boolean = false
    agreementServiceCheck: boolean = false
    agreementServiceCheckError: string = ""
    isOpenAgreementServiceModal: boolean = false

    agreementPrivacyPolicyCheck: boolean = false
    agreementPrivacyPolicyCheckError: string = ""
    isOpenAgreementPrivacyPolicyModal: boolean = false

    // VueUser
    fullName = ""
    email = ""
    address = ""
    tel = ""

    // Check Validation
    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsEmail),
            ValidationHelper.requiredInputParts(this.inputPartsPassword),
        ]
        return checkList.every(value => value === true)
    }

    beforeAgree() {
        const checkList: boolean[] = []
        if (this.needAgreementBySiteCheck || this.ng_agreements.includes(2)) {
            checkList.push(this.checkAgreementService())
        }
        if (this.ng_agreements.includes(1)) {
            checkList.push(this.checkAgreement())
        }
        if (this.ng_agreements.includes(3)) {
            checkList.push(this.checkAgreementPrivacyPolicy())
        }
        return checkList.every(value => value === true)
    }

    checkAgreementService () {
        this.agreementServiceCheckError = this.agreementServiceCheck ? '' : '利用規約に同意いただけない場合は、利用できません。'
        return this.agreementServiceCheck
    }

    checkAgreement () {
        this.agreementCheckError = this.agreementCheck ? '' : '会員規約に同意いただけない場合は、利用できません。'
        return this.agreementCheck
    }

    checkAgreementPrivacyPolicy () {
        this.agreementPrivacyPolicyCheckError = this.agreementPrivacyPolicyCheck ? '' : 'プライバシーポリシーに同意いただけない場合は、利用できません。'
        return this.agreementPrivacyPolicyCheck
    }

    // Action signin
    async confirm() {
        // Validation Check
        this.isError = false
        this.isAgreeCheckBefore = true
        this.agreementCheckError = ''
        this.agreementServiceCheckError = ''
        this.agreementPrivacyPolicyCheckError = ''
        this.isCheck = this.beforeCheck()
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }
        if (this.isAgreeCheck) {
            this.isAgreeCheckBefore = this.beforeAgree()
            if (!this.isAgreeCheckBefore) {
                VueHelper.scrollTop()
                return
            }
        }
        // Make Request
        const request = new PostLoginRequest (this.inputPartsEmail.value, this.inputPartsPassword.value, this.provisionShopCode, this.isAgreeCheck, this.backUrlCode, encodeURIComponent(this.backUrlPrm), false)
        // Call API
        VueHelper.lock()
        const response: any = await this.$api.postLogin(request);
        VueHelper.unlock()
        // API Resuponse Handle
        if (response.status === HttpStatus.LOCKED.code) {
            this.isAgreeCheck = true
            this.fullName = response.data.full_name_kanji
            this.email = response.data.email
            this.address = response.data.address
            this.tel = response.data.tel
            this.needAgreementBySiteCheck = response.data.is_mapping_first_login
            this.needAgreementCheck = response.data.is_old_agreement_check
            this.ng_agreements = response.data.ng_agreements
        }
        else {
            if (!ErrorHelper.apiErrorHandler(
                response,
                this,
                [
                    this.inputPartsEmail,
                    this.inputPartsPassword,
                ]
            )) {
                VueHelper.scrollTop()
                this.isError = true
            }
        }
    }

    openAgreementServiceModal () {
        this.isOpenAgreementServiceModal = true
    }

    closeAgreementServiceModal () {
        this.isOpenAgreementServiceModal = false
    }

    openAgreementModal () {
        this.isOpenAgreementModal = true
    }

    closeAgreementModal () {
        this.isOpenAgreementModal = false
    }

    openAgreementPrivacyPolicyModal () {
        this.isOpenAgreementPrivacyPolicyModal = true
    }

    closeAgreementPrivacyPolicyModal () {
        this.isOpenAgreementPrivacyPolicyModal = false
    }

    changeAgreementCheck(value: boolean) {
        this.agreementCheck = value
    }
    changeAgreementServiceCheck(value: boolean) {
        this.agreementServiceCheck = value
    }
    changePrivacyPolicyCheck(value: boolean) {
        this.agreementPrivacyPolicyCheck = value
    }

    backLogin() {
        this.isAgreeCheck = false
        this.isAgreeCheckBefore = true
    }
    
    // Action Press Back Button
    historyBack() {
        VueHelper.historyBack(this)
    }

    // Action Press Recovery Password
    movePasswordRecovery() {
        if (this.backUrlCode) {
            VueHelper.transitionByLocation(this, { path: '/password-recovery', query: {
                provision_shop_code: this.provisionShopCode,
                back_url_code:this.backUrlCode,
                back_url_prm:this.backUrlPrm,
            }})
        }
        else {
            VueHelper.transitionByLocation(this, { path: '/password-recovery', query: { provision_shop_code: this.provisionShopCode }})
        }
    }

    // Action Press For Beginners
    moveSignup() {
        if (this.backUrlCode) {
            VueHelper.transitionByLocation(this,{ path: '/email-confirmation', query: {
                provision_shop_code: this.provisionShopCode,
                back_url_code:this.backUrlCode,
                back_url_prm:this.backUrlPrm,
            }})
        }
        else {
            VueHelper.transitionByLocation(this,{ path: '/email-confirmation', query: { provision_shop_code: this.provisionShopCode }})
        }
    }
}
