








































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/atoms/Modal.vue';
import H2 from '@/components/atoms/H2.vue';

@Component({
    components: {
        Modal,
        H2
    },
})
export default class AgreementModal extends Vue {
    @Prop({ default: '' }) private label!: string;

    close() {
        this.$emit('close')
    }
}
