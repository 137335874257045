import axios from '@/plugins/axios'
import external_axios from '@/plugins/external_axios'
import * as typesAPI from '@/types/api/types-api'
import { backendAPIConfig } from '@baseConfig'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const jsonpAdapter = require('axios-jsonp')

class Api {

    public async postCustomerMailConfirmation (request: typesAPI.CustomerEmailConfirmationRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.post(backendAPIConfig.endpoint + '/web/customer/mail-auth', {
                email: request.email,
                provision_shop_code: request.provisionShopCode,
                back_url_code: request.backUrlCode,
                back_url_prm: request.backUrlPrm
            })
        }
        return res
    }

    public async getCustomerMailAuth (request: typesAPI.CustomerMailAuthRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.get(
                backendAPIConfig.endpoint + '/web/customer/mail-auth?auth_key=' + request.authKey
            )
        }
        return res
    }

    public async postCustomerSignup (request: typesAPI.CustomerSignupRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.post(
                backendAPIConfig.endpoint + '/web/customer',{
                    auth_key: request.authkey,
                    password: request.password,
                    last_name_kanji: request.lastNameKanji,
                    first_name_kanji: request.firstNameKanji,
                    last_name_kana: request.lastNameKana,
                    first_name_kana: request.firstNameKana,
                    tel: request.tel,
                    zip_code: request.zipCode,
                    state: request.state,
                    city: request.city,
                    address1: request.address1,
                    address2: request.address2,
                    sex: request.sex,
                    birthday: request.birthday,
                    check_only: request.checkOnly,
                    data_by_site: request.dataBySite
                }
            )
        }
        return res
    }

    public async postCustomerPasswordMailAuth (request: typesAPI.PostCustomerPasswordMailAuthRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.post(backendAPIConfig.endpoint + '/web/customer/password-mail-auth', {
                email: request.email,
                tel: request.tel,
                provision_shop_code: request.provisionShopCode,
                back_url_code: request.backUrlCode,
                back_url_prm: request.backUrlPrm
            })
        }
        return res
    }

    public async getCustomerPasswordMailAuth (request: typesAPI.GetCustomerPasswordMailAuthRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.get(
                backendAPIConfig.endpoint + '/web/customer/password-mail-auth?auth_key=' + request.authKey
            )
        }
        return res
    }

    public async postCustomerPasswordRecovery (request: typesAPI.PostCustomerPasswordRecoveryRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.post(backendAPIConfig.endpoint + '/web/customer/password-recovery', {
                auth_key: request.authKey,
                email: request.email,
                password: request.password
            })
        }
        return res
    }

    public async postLogin(request: typesAPI.PostLoginRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.post(backendAPIConfig.endpoint + '/web/login', {
                email: request.email,
                password: request.password,
                provision_shop_code: request.provisionShopCode,
                checked: request.checked,
                back_url_code: request.backUrlCode,
                back_url_prm: request.backUrlPrm,
                is_new_registration_by: request.isNewRegistrationBy,
                data_by_site: request.dataBySite
            })
        }
        return res
    }

    public async putCustomerEdit(request: typesAPI.PutCustomerEdit): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.put(backendAPIConfig.endpoint + '/web/auth/edit', {
                last_name_kanji: request.lastNameKanji,
                first_name_kanji: request.firstNameKanji,
                last_name_kana: request.lastNameKana,
                first_name_kana: request.firstNameKana,
                tel: request.tel,
                zip_code: request.zipCode,
                state: request.state,
                city: request.city,
                address1: request.address1,
                address2: request.address2,
                sex: request.sex,
                birthday: request.birthday,
                check_only: request.checkOnly,
                provision_shop_code:request.provisionShopCode
            })
        }
        return res
    }

    public async putCustomerMailEdit(request: typesAPI.PutCustomerMailEdit): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.put(backendAPIConfig.endpoint + '/web/edit/mail/complete', {
                auth_key: request.authKey
            })
        }
        return res
    }

    public async postCustomerMailEdit(request: typesAPI.PostCustomerMailEdit): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.post(backendAPIConfig.endpoint + '/web/auth/edit/mail', {
                email: request.email,
                provision_shop_code: request.provisionShopCode
            })
        }
        return res
    }

    public async putCustomerPasswordEdit(request: typesAPI.PutCustomerPasswordEdit): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.put(backendAPIConfig.endpoint + '/web/auth/edit/password', {
                email: request.email,
                old_password: request.oldPassword,
                new_password: request.newPassword,
                provision_shop_code: request.provisionShopCode
            })
        }
        return res
    }

    public async putCustomerWithdraw(request: typesAPI.PutCustomerWithdraw): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.put(backendAPIConfig.endpoint + '/web/auth/withdraw', {
                email: request.email,
                password: request.password,
                provision_shop_code: request.provisionShopCode
            })
        }
        return res
    }

    public async getCustomerEdit(request: typesAPI.GetCustomerEditRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.get(backendAPIConfig.endpoint + '/web/auth/edit?provision_shop_code=' + request.provisionShopCode)
        }
        return res
    }

    public async getAuthKeyRedirect(request: typesAPI.GetAuthKeyRedirect): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.get(backendAPIConfig.endpoint + '/web/temporary_redirect_url/auth_key?auth_key=' + request.authKey)
        }
        return res
    }

    public async searchAddress(zipCode: string): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        res = await external_axios.get('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipCode,
            {
                adapter: jsonpAdapter
            }
        )
        return res
    }
    
    public async postOAuthSetup(request: typesAPI.PostOAuthSetupRequest): Promise<typesAPI.APIResponse> {
        let res: typesAPI.APIResponse = {}
        if (request.mock) {
            // do nothing
        } else {
            res = await axios.post(backendAPIConfig.endpoint + '/web/login/oauth/setup', {
                provision_shop_code: request.provisionShopCode,
                back_url_code: request.backUrlCode,
                back_url_prm: request.backUrlPrm,
            })
        }
        return res
    }

}

export default {
    install(Vue: any) {
        const api = new Api()
        Vue.prototype.$api = api;
    },
};