









import { Component, Vue } from 'vue-property-decorator';
import BtnBase from '@/components/atoms/BtnBase.vue';

@Component({
    components: {
        BtnBase
    }
})
export default class Error extends Vue {
    back () {
        history.back()
    }
}
