















import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { CustomerEmailConfirmationRequest } from '@/types/api/types-api';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { ValidationHelper } from '@/helpers/validate';
import { ErrorHelper } from '@/helpers/error';
import { VueHelper } from '@/helpers/vue';
import { KoseIDSite } from '@/types/converter/convert';
import { HttpStatus, InputEventType } from '@/types/converter/convert';
import { Const } from '@/const/const';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts
    },
})
export default class DefaultEmailConfirmation extends Vue {

    @Prop() private provisionShopCode!: string;
    @Prop() private backUrlCode!: string
    @Prop() private backUrlPrm!: string

    mailInputParts: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "(例)xxxxx@xxxxx.xxxxx", 100)

    async send () {
        if (!this.beforeCheck()) {
            return
        }
        const request: CustomerEmailConfirmationRequest = new CustomerEmailConfirmationRequest(
            this.mailInputParts.value,
            this.provisionShopCode,
            this.backUrlCode,
            encodeURIComponent(this.backUrlPrm)
        )
        VueHelper.lock()
        const response: any = await this.$api.postCustomerMailConfirmation(request);
        VueHelper.unlock()

        if (response.status === HttpStatus.OK.code) {
            VueHelper.move(this, '/email-complete?provision_shop_code=' + this.provisionShopCode)
        } else {
            if (response.status === HttpStatus.CONFLICT.code) {
                this.mailInputParts.error = Const.ERROR_MESSAGE_EXIST_EMAIL
                return
            } else {
                ErrorHelper.apiErrorHandler(
                    response,
                    this,
                    [
                        this.mailInputParts
                    ]
                )
            }
        }
    }

    get isAnotherTabSite (): boolean {
        return KoseIDSite.isAnotherTabSite(this.provisionShopCode)
    }

    beforeCheck (): boolean {
        return ValidationHelper.requiredInputParts(this.mailInputParts)
    }

    close () {
        window.close()
    }

    back () {
        VueHelper.historyBack(this)
    }
}
