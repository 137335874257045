








































import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import CheckBoxBase from '@/components/atoms/CheckBoxBase.vue';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { VueHelper } from '@/helpers/vue';
import { PostCustomerPasswordRecoveryRequest } from '@/types/api/types-api';
import { ValidationHelper } from '@/helpers/validate';
import { ErrorHelper } from '@/helpers/error';
import { HttpStatus, InputEventType } from '@/types/converter/convert';
import { Const } from '@/const/const';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts,
        H2,
        CheckBoxBase,
        InputBase
    },
})
export default class DefaultPasswordRecoveryInput extends Vue {

    @Prop() private authKey!: string;
    @Prop() private provisionShopCode!: string;

    isCheck: boolean = true

    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "(例)xxxxx@xxxxx.xxxxx", 100)
    inputPartsPassword1: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, true, "", 20, undefined, "", "password")
    inputPartsPassword2: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, true, "", 20, undefined, "", "password")

    // method
    async change() {
        this.isCheck = this.beforeCheck()
        
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }

        const request: PostCustomerPasswordRecoveryRequest = new PostCustomerPasswordRecoveryRequest(
            this.authKey,
            this.inputPartsEmail.value,
            this.inputPartsPassword1.value,
        )
        VueHelper.lock()
        const response: any = await this.$api.postCustomerPasswordRecovery(request);
        VueHelper.unlock()
        const apiResult = ErrorHelper.apiErrorHandler(
            response,
            this,
            [
                this.inputPartsEmail,
                this.inputPartsPassword1
            ]
        )
        if (apiResult) {
            VueHelper.scrollTop()
            VueHelper.move(this, '/password-recovery/complete?provision_shop_code=' + this.provisionShopCode + '&auth_key=' + this.authKey)
        } else {
            this.isCheck = false
            VueHelper.scrollTop()
            if (response.status === HttpStatus.BAD_REQUEST.code
                && response.data.type == Const.KEY_RESPONSE_BAD_REQUEST_TYPE_AUTH_KEY) {
                VueHelper.move(this, '/error')
            }
        }
    }

    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsEmail),
            ValidationHelper.requiredInputParts(this.inputPartsPassword1),
            this.checkSamePassword(this.inputPartsPassword1, this.inputPartsPassword2)
        ]
        return checkList.every(value => value === true)
    }

    checkSamePassword (password1: TypeInputParts, password2: TypeInputParts) {
        // 既にエラーがある場合return
        if (password1.error) {
            return true
        }
        const check: boolean = password1.value === password2.value
        if (!check) {
            password1.error = '入力されたパスワードと確認用パスワードが一致しません'
        }
        return check
    }
    inputPassword1 (value: string) {
        this.inputPartsPassword1.value = value
    }
    inputPassword2 (value: string) {
        this.inputPartsPassword2.value = value
    }
}
