








import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Kanban from '@/components/atoms/Kanban.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { VueHelper } from '@/helpers/vue';
import { GetAuthKeyRedirect } from '@/types/api/types-api';
import { HttpStatus } from '@/types/converter/convert';
import { ErrorHelper } from '@/helpers/error';

@Component({
    components: {
        Kanban,
        BtnBase,
        InputParts,

    },
})
export default class PRS0000001PasswordRecoveryEmailComplete extends Vue {

    @Prop() private authKey!: string;
    @Prop() private provisionShopCode!: string;

    async backLogin() {
        VueHelper.lock()
        const request: GetAuthKeyRedirect = new GetAuthKeyRedirect(
            this.provisionShopCode,
            this.authKey
        )
        const response: any = await this.$api.getAuthKeyRedirect(request);
        if (response.status == HttpStatus.FOUND.code) {
            ErrorHelper.apiErrorHandler(response, this)
        } else {
            VueHelper.transitionByLocation(this,{ path: '/login', query: { provision_shop_code: this.provisionShopCode }})
        }
        VueHelper.unlock()
    }
}
