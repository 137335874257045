import { TypeInputParts, TypeSelectParts, TypeSelectBirthDayParts } from '@/types/components/types-parts'
import { InputEventType } from '@/types/converter/convert';

export class PartsHelper {
    public static createInputParts (
        label: string,
        name: string,
        inputEventType: InputEventType,
        required: boolean = false,
        placeholder: string = "",
        maxLength: number = 200,
        size: number | undefined = undefined,
        explain: string | string[] = "",
        type: string = "",
        value: string = "",
        error: string = "",
        disabled: boolean = false
    ): TypeInputParts {
        return {
            label: label,
            name: name,
            inputEventType: inputEventType,
            value: value,
            placeholder:placeholder,
            maxLength: maxLength,
            size: size,
            explain: explain,
            type: type,
            required: required,
            disabled: disabled,
            error: error
        }
    }

    public static createSelectParts(
        label: string,
        name: string,
        items: object[],
        required: boolean = false,
        value: string = "",
        error: string = ""
    ): TypeSelectParts {
        return {
            label: label,
            name: name,
            items: items,
            required: required,
            value: value,
            error: error
        }
    }

    public static createBirthdayParts(
        label: string,
        name: string,
        yyyy: string = "",
        mm: string = "",
        dd: string = "",
        error: string = ""
    ): TypeSelectBirthDayParts {
        return {
            label: label,
            name: name,
            yyyy: yyyy,
            mm: mm,
            dd: dd,
            error: error
        }
    }
}
