











import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultPasswordEditComplete from '@/components/organisms/default/password_edit/DefaultPasswordEditComplete.vue';
import PRS0000001PasswordEditComplete from '@/components/organisms/PRS0000001/password_edit/PRS0000001PasswordEditComplete.vue';
import { VueHelper } from '@/helpers/vue';
import { AllowSettings } from '@/types/converter/convert';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultPasswordEditComplete,
        PRS0000001PasswordEditComplete,
        PRS0000001SubFooter
    },
})
export default class PasswordEditComplete extends Vue {

    private isLoading: boolean = true
    @Prop() private provisionShopCode! : string;

    mounted () {
        if (!AllowSettings.isUsePasswordEdit(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        this.isLoading = false
    }

}
