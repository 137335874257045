import Vue from 'vue'
import Vuex from 'vuex'
import { IUiState } from '@/types/store/types-ui'

Vue.use(Vuex)

export interface IRootState {
    ui: IUiState,
}

export default new Vuex.Store<IRootState>({})
