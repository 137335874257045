





















import { Component, Vue } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import DefaultPasswordRecoveryInput from '@/components/organisms/default/password_recovery/DefaultPasswordRecoveryInput.vue';
import PRS0000001PasswordRecoveryInput from '@/components/organisms/PRS0000001/password_recovery/PRS0000001PasswordRecoveryInput.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { VueHelper } from '@/helpers/vue';
import { GetCustomerPasswordMailAuthRequest } from '@/types/api/types-api';
import { HttpStatus } from '@/types/converter/convert';
import { ErrorHelper } from '@/helpers/error';
import { KoseIDSite, AllowSettings } from '@/types/converter/convert';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';


@Component({
    components: {
        Title,
        BtnBase,
        InputParts,
        DefaultPasswordRecoveryInput,
        PRS0000001PasswordRecoveryInput,
        PRS0000001SubFooter
    },
})
export default class PasswordRecoveryInput extends Vue {
    private isLoading: boolean = true
    private isAuthKeyExpired: boolean = false
    private authKey: string = ""
    private provisionShopCode: string = ""

    private expired_link: string = ""

    async created () {
        this.authKey = VueHelper.getParameter(this, 'auth_key')
        this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')

        if (!AllowSettings.isUsePasswordRecovery(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        
        const request: GetCustomerPasswordMailAuthRequest = new GetCustomerPasswordMailAuthRequest(
            this.authKey
        )
        VueHelper.lock()
        const response: any = await this.$api.getCustomerPasswordMailAuth(request);
        const apiResult = ErrorHelper.apiErrorHandler(response, this)

        if (!apiResult) {
            if (response.status === HttpStatus.BAD_REQUEST.code) {
                this.isAuthKeyExpired = true
                this.expired_link = '/password-recovery?provision_shop_code=' + this.provisionShopCode
            }
        }
        VueHelper.unlock()
        this.isLoading = false
    }
    get isOLCDecorte () {
        return KoseIDSite.isOLCDecorteGroup(this.provisionShopCode)
    }

    get isAuthKeyExpireHiddenLinkGroup () {
        return KoseIDSite.isOLCDecorteGroup(this.provisionShopCode) ||
            KoseIDSite.isOLCJillGroup(this.provisionShopCode) ||
            KoseIDSite.isOLCMaisonGroup(this.provisionShopCode)
    }
}
