




import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component
export default class BtnBase extends Vue {
  isNext = false
  isPrevious = false
  isDanger = false

  @Prop({ default: '' }) private label!: string;

  @Prop({ default: '' }) private addClass!: string

  @Prop({ default: false }) private isDisabled!: boolean

  @Prop({ default: '' }) private addStyle!: string;

  @Emit('onClick')
  onClick () {
      // do nothing
  }

  created () {
      if (this.addClass === 'btn-next') {
          this.isNext = true
      } else if (this.addClass === 'btn-previous') {
          this.isPrevious = true
      } else if (this.addClass === 'btn-danger') {
          this.isDanger = true
      } else {
          this.isNext = true
      }
  }
}
