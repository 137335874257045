














import { Component, Vue, Prop } from 'vue-property-decorator';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import H2 from '@/components/atoms/H2.vue';
import { VueHelper } from '@/helpers/vue';
import AgreementModal from '@/components/molecules/modal/AgreementProductRegistrationModal.vue';
import AgreementPrivacyModal from '@/components/molecules/modal/PrivacyPolicyProductRegistrationModal.vue';
import InquiryModal from '@/components/molecules/modal/ProductResistrationInquiryModal.vue';

@Component({
    components: {
        Title,
        BtnBase,
        H2,
        AgreementModal,
        AgreementPrivacyModal,
        InquiryModal
    },
})
export default class DefaultAuth extends Vue {

    @Prop() private provisionShopCode!: string;

    isOpenFooterPrivacyModal: boolean = false
    isOpenFooterAgreementModal: boolean = false
    isOpenInquiryModal: boolean = false

    onClickMoveAuthEditCustomer() {
        VueHelper.transitionByLocation(this,{ path: `/edit/` + this.provisionShopCode})
    }

    onClickMoveMailMagazine() {
        VueHelper.moveShopPage(this, this.provisionShopCode, '/customer/mail_magazine')
    }

    openFooterPrivacyModel () {
        this.isOpenFooterPrivacyModal = true
    }

    closeFooterPrivacyModal () {
        this.isOpenFooterPrivacyModal = false
    }

    openFooterAgreementModal () {
        this.isOpenFooterAgreementModal = true
    }

    closeFooterAgreementModal () {
        this.isOpenFooterAgreementModal = false
    }


}
