


























import { Component, Vue } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import DefaultSignup from '@/components/organisms/default/signup/DefaultSignup.vue';
import PRS0000001Signup from '@/components/organisms/PRS0000001/signup/PRS0000001Signup.vue';
import OLC0000001Signup from '@/components/organisms/OLC0000001/signup/OLC0000001Signup.vue';
import OLC0000004Signup from '@/components/organisms/OLC0000004/signup/OLC0000004Signup.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { VueHelper } from '@/helpers/vue';
import { CustomerMailAuthRequest } from '@/types/api/types-api';
import { HttpStatus } from '@/types/converter/convert';
import { ErrorHelper } from '@/helpers/error';
import { KoseIDSite, AllowSettings } from '@/types/converter/convert';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts,
        DefaultSignup,
        PRS0000001Signup,
        OLC0000001Signup,
        OLC0000004Signup,
        PRS0000001SubFooter
    },
})
export default class Signup extends Vue {
    private isLoading: boolean = true
    private isAuthKeyExpired: boolean = false
    private authKey: string = ""
    private provisionShopCode: string = ""
    private email: string = ""

    private expired_link: string = ""

    async created () {
        this.authKey = VueHelper.getParameter(this, 'auth_key')
        this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')
        if (!AllowSettings.isUseSignup(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        
        const request: CustomerMailAuthRequest = new CustomerMailAuthRequest(
            this.authKey
        )
        VueHelper.lock()
        const response: any = await this.$api.getCustomerMailAuth(request);
        const apiResult = ErrorHelper.apiErrorHandler(response, this)

        if (apiResult) {
            this.email = response.data.data.email
        } else {
            if (response.status === HttpStatus.BAD_REQUEST.code || response.status === HttpStatus.CONFLICT.code) {
                this.isAuthKeyExpired = true
                this.expired_link = '/email-confirmation?provision_shop_code=' + this.provisionShopCode
            }
        }
        VueHelper.unlock()
        this.isLoading = false
    }

    get isOLCDecorte () {
        return KoseIDSite.isOLCDecorteGroup(this.provisionShopCode)
    }

    get isOLCSekkisei () {
        return KoseIDSite.OLC_SEKKISEI.code === this.provisionShopCode
    }

    get isAuthKeyExpireHiddenLinkGroup () {
        return KoseIDSite.isOLCDecorteGroup(this.provisionShopCode) ||
            KoseIDSite.isOLCJillGroup(this.provisionShopCode) ||
            KoseIDSite.isOLCMaisonGroup(this.provisionShopCode)
    }
}
