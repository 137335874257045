















import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Kanban from '@/components/atoms/Kanban.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { PostCustomerPasswordMailAuthRequest } from '@/types/api/types-api';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { ValidationHelper } from '@/helpers/validate';
import { ErrorHelper } from '@/helpers/error';
import { VueHelper } from '@/helpers/vue';
import { HttpStatus, InputEventType } from '@/types/converter/convert';
import { Const } from '@/const/const';

@Component({
    components: {
        Kanban,
        BtnBase,
        InputParts
    },
})
export default class PRS0000001PasswordRecoveryEmailConfirmation extends Vue {

    @Prop() private provisionShopCode!: string;
    @Prop() private backUrlCode!: string
    @Prop() private backUrlPrm!: string

    inputPartsMail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "(例)xxxxx@xxxxx.xxxxx", 100)
    inputPartsTel: TypeInputParts = PartsHelper.createInputParts("連絡先電話番号", "tel", InputEventType.NONE, true, "", 11, undefined, "※電話番号はハイフンなしで入力してください")

    async send () {
        if (!this.beforeCheck()) {
            return
        }
        const request: PostCustomerPasswordMailAuthRequest = new PostCustomerPasswordMailAuthRequest(
            this.inputPartsMail.value,
            this.inputPartsTel.value,
            this.provisionShopCode,
            this.backUrlCode,
            encodeURIComponent(this.backUrlPrm)
        )
        VueHelper.lock()
        const response: any = await this.$api.postCustomerPasswordMailAuth(request);
        VueHelper.unlock()

        const apiResult = ErrorHelper.apiErrorHandler(
            response,
            this,
            [
                this.inputPartsMail,
                this.inputPartsTel
            ]
        )
        if (apiResult) {
            VueHelper.move(this, '/password-recovery/email-complete?provision_shop_code=' + this.provisionShopCode)
        } else {
            if (response.status === HttpStatus.NOT_FOUND.code) {
                this.inputPartsMail.error = Const.ERROR_MESSAGE_NOT_EXIST_EMAIL_OR_TEL
            }
        }
    }

    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsMail),
            ValidationHelper.requiredInputParts(this.inputPartsTel)
        ]
        return checkList.every(value => value === true)
    }

    // Action Press Back Button
    backToppage() {
        VueHelper.historyBack(this)
    }
}
