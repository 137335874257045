




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class H2 extends Vue {
    @Prop({ default: '' }) private label!: string;
}
