





import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class SelectBase extends Vue {
    @Prop({ default: '' }) private name!: string;

    @Prop({ default: '' }) private value!: string;

    @Prop({}) private items!: object[];

    set selectValue (value: string) {
        this.$emit('selectValue', value)
    }

    get selectValue () {
        return this.value
    }
}

