





















import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { VueHelper } from '@/helpers/vue';
import { KoseIDSite } from '@/types/converter/convert';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts
    },
})
export default class DefaultMaildEditSend extends Vue {

    @Prop() private provisionShopCode!: string;

    get isAnotherTabSSOSite (): boolean {
        return KoseIDSite.isAnotherTabSSOSite(this.provisionShopCode)
    }

    backMyPage() {
        VueHelper.moveMyPage(this, this.provisionShopCode)
    }
}
