export class Const {
  static ERROR_MESSAGE_EXIST_EMAIL: string = '入力されたメールアドレスは既に使用されています。'
  static ERROR_MESSAGE_NOT_EXIST_EMAIL_OR_TEL = '連絡先電話番号 または メールアドレス に誤りがあります。'

  static KEY_RESPONSE_BAD_REQUEST_TYPE_VALIDATE = 'validate_error'
  static KEY_RESPONSE_BAD_REQUEST_TYPE_AUTH_KEY = 'auth_key_error'

  static PROVISION_SHOP_CODE_OLC_DECORTE = 'OLC0000001'
  static PROVISION_SHOP_CODE_OLC_JILL = 'OLC0000002'
  static PROVISION_SHOP_CODE_OLC_MAISON = 'OLC0000003'
  static PROVISION_SHOP_CODE_OLC_SEKKISEI = 'OLC0000004'
  static PROVISION_SHOP_CODE_PR = 'PRS0000001'
}
