























import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultMailEditComplete from '@/components/organisms/default/mail_edit/DefaultMailEditComplete.vue';
import PRS0000001MailEditComplete from '@/components/organisms/PRS0000001/mail_edit/PRS0000001MailEditComplete.vue';
import { VueHelper } from '@/helpers/vue';
import { AllowSettings } from '@/types/converter/convert';
import { PutCustomerMailEdit } from '@/types/api/types-api';
import { HttpStatus } from '@/types/converter/convert';
import { ErrorHelper } from '@/helpers/error';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';


@Component({
    components: {
        DefaultMailEditComplete,
        PRS0000001MailEditComplete,
        PRS0000001SubFooter
    },
})
export default class MailEditComplete extends Vue {

    private isLoading: boolean = true
    private isAuthKeyExpired: boolean = false
    private authKey: string = ""
    @Prop() private provisionShopCode!: string;
    private response: any;

    private expired_link: string = ""

    async created () {
        this.authKey = VueHelper.getParameter(this, 'auth_key')
        if (!AllowSettings.isUseMailEdit(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        const request: PutCustomerMailEdit = new PutCustomerMailEdit(this.authKey, this.provisionShopCode)
        VueHelper.lock()
        const response: any = await this.$api.putCustomerMailEdit(request);
        if (response.status !== HttpStatus.OK.code) {
            if (response.status === HttpStatus.BAD_REQUEST.code || response.status === HttpStatus.CONFLICT.code) {
                this.isAuthKeyExpired = true
                this.expired_link = '/mail-edit/input/' + this.provisionShopCode
            }
            else {
                ErrorHelper.apiErrorHandler(
                    response,
                    this
                )
            }
        }
        VueHelper.unlock()
        this.response = response.data
        this.isLoading = false
    }

}
