








import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Kanban from '@/components/atoms/Kanban.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { VueHelper } from '@/helpers/vue';

@Component({
    components: {
        Kanban,
        BtnBase,
        InputParts
    },
})
export default class PRS0000001EditComplete extends Vue {

    @Prop() private provisionShopCode!: string;

    backToppage() {
        VueHelper.backToppage(this, this.provisionShopCode)
    }
}
