










import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectBase from '../atoms/SelectBase.vue'
import H2 from '../atoms/H2.vue'
import { TypeSelectParts } from '@/types/components/types-parts';

@Component({
    components: {
        SelectBase,
        H2
    }
})
export default class SelectParts extends Vue {
    @Prop({ default: undefined }) private selectParts!: TypeSelectParts;

    selectValue (value: string) {
        this.selectParts.value = value
    }
}
