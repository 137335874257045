import Axios from 'axios'
import { VueHelper } from '@/helpers/vue';

const _axios = Axios.create({withCredentials: true})

_axios.interceptors.response.use(
    response => response,
    error => {
        try {
            error.response.status
        } catch (error) {
            VueHelper.unlock()
            window.location.href = '/error'
        }
        return error.response
    }
)
export default _axios
