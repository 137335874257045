

















import { Component, Vue } from 'vue-property-decorator';
import DefaultLogin from '@/components/organisms/default/login/DefaultLogin.vue';
import PRS0000001Login from '@/components/organisms/PRS0000001/login/PRS0000001Login.vue';
import OLC0000001Login from '@/components/organisms/OLC0000001/login/OLC0000001Login.vue';
import OLC0000002Login from '@/components/organisms/OLC0000002/login/OLC0000002Login.vue';
import OLC0000003Login from '@/components/organisms/OLC0000003/login/OLC0000003Login.vue';
import { VueHelper } from '@/helpers/vue';
import { StringHelper } from '@/helpers/string';
import { ErrorHelper } from '@/helpers/error';
import { KoseIDSite, AllowSettings, HttpStatus } from '@/types/converter/convert';
import { PostOAuthSetupRequest } from '@/types/api/types-api';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultLogin,
        PRS0000001Login,
        OLC0000001Login,
        OLC0000002Login,
        OLC0000003Login,
        PRS0000001SubFooter
    },
})
export default class Login extends Vue {

    private isLoading: boolean = true
    private provisionShopCode: string = ""
    private backUrlCode: string = ""
    private backUrlPrm: string = ""
    private autoLogin: boolean = false
    async mounted () {
        this.backUrlCode = VueHelper.getParameter(this, 'back_url_code')
        this.backUrlPrm = VueHelper.getParameter(this, 'back_url_prm')
        this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')
        this.autoLogin = StringHelper.convertStrToBoolean(VueHelper.getParameter(this, 'auto'))
        
        if (!AllowSettings.isUseLogin(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        
        const site = KoseIDSite.findValue(this.provisionShopCode)
        if (site && site.config.autLogin && this.autoLogin) {
            const request: PostOAuthSetupRequest = new PostOAuthSetupRequest(
                this.provisionShopCode, this.backUrlCode, this.backUrlPrm
            )
            VueHelper.lock()
            const response: any = await this.$api.postOAuthSetup(request);
            VueHelper.unlock()

            // API Resuponse Handle
            // 401が返ってくることは想定されないが、無限ループ対策
            if (response.status === HttpStatus.UNAUTHORIZED.code) {
                VueHelper.move(this, '/error')
                return
            } else {
                if (!ErrorHelper.apiErrorHandler(response, this)) {
                    VueHelper.move(this, '/error')
                }
                return
            }
        }
        this.isLoading = false
    }

    get isOLCDecorte () {
        return KoseIDSite.isOLCDecorteGroup(this.provisionShopCode)
    }

    get isOLCJill () {
        return KoseIDSite.isOLCJillGroup(this.provisionShopCode)
    }

    get isMaisonJill () {
        return KoseIDSite.isOLCMaisonGroup(this.provisionShopCode)
    }
}
