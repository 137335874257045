




























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import SelectParts from '@/components/molecules/SelectParts.vue';
import SelectBirthDayParts from '@/components/molecules/SelectBirthDayParts.vue';
import AgreementKoseIdProductRegistrationModal from '@/components/molecules/modal/AgreementKoseIdProductRegistrationModal.vue';
import PrivacyPolicyProductRegistrationModal from '@/components/molecules/modal/PrivacyPolicyProductRegistrationModal.vue';
import AgreementDecorteModal from '@/components/molecules/modal/AgreementProductRegistrationModal.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import Kanban from '@/components/atoms/Kanban.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import CheckBoxBase from '@/components/atoms/CheckBoxBase.vue';
import { TypeInputParts, TypeSelectParts, TypeSelectBirthDayParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { VueHelper } from '@/helpers/vue';
import { StringHelper } from '@/helpers/string';
import { Prefectures, Sex } from '@/types/converter/convert';
import PRS0000001SignupConfirmation from '@/components/organisms/PRS0000001/signup/PRS0000001SignupConfirmation.vue';
import { CustomerSignupRequest } from '@/types/api/types-api';
import { ValidationHelper } from '@/helpers/validate';
import { ErrorHelper } from '@/helpers/error';
import { HttpStatus, InputEventType } from '@/types/converter/convert';
import { Const } from '@/const/const';

@Component({
    components: {
        Kanban,
        BtnBase,
        InputParts,
        SelectParts,
        SelectBirthDayParts,
        H2,
        CheckBoxBase,
        InputBase,
        PRS0000001SignupConfirmation,
        AgreementKoseIdProductRegistrationModal,
        AgreementDecorteModal,
        PrivacyPolicyProductRegistrationModal
    },
})
export default class PRS0000001Signup extends Vue {

    @Prop() private email!: string;
    @Prop() private authKey!: string;
    @Prop() private provisionShopCode!: string;

    isConfirm: boolean = false
    isCheck: boolean = true

    request: CustomerSignupRequest | null = null

    inputPartsLastName: TypeInputParts = PartsHelper.createInputParts("お名前(姓)", "last_name_kanji", InputEventType.ZENKAKU, true, "(例)xxxxx", 10)
    inputPartsFirstName: TypeInputParts = PartsHelper.createInputParts("お名前(名)", "first_name_kanji", InputEventType.ZENKAKU, true, "(例)xxxxx", 10)

    inputPartsLastNameKana: TypeInputParts = PartsHelper.createInputParts("フリガナ(セイ)", "last_name_kana", InputEventType.ZENKAKU, true, "(例)xxxxx", 20)
    inputPartsFirstNameKana: TypeInputParts = PartsHelper.createInputParts("フリガナ(メイ)", "first_name_kana", InputEventType.ZENKAKU, true, "(例)xxxxx", 20)

    inputPartsTel: TypeInputParts = PartsHelper.createInputParts("電話番号", "tel", InputEventType.HANKAKU, true, "(例)03xxxxxxxx", 11, 18, "※ハイフンなしで入力してください")
    inputPartsZipCode: TypeInputParts = PartsHelper.createInputParts("郵便番号", "zip_code", InputEventType.HANKAKU, true, "(例)1234567", 7, 18, "※ハイフンなしで入力してください")

    selectPreficture: TypeSelectParts = PartsHelper.createSelectParts("都道府県", "state", Prefectures.getSelectList(), true)
    inputPartsCity: TypeInputParts = PartsHelper.createInputParts("市区町村", "city", InputEventType.ZENKAKU, true, "(例)xxxxx区", 40, 18)
    inputPartsAddress1: TypeInputParts = PartsHelper.createInputParts("丁目・番地", "address1", InputEventType.ZENKAKU, true, "(例)xxxxx", 40)
    inputPartsAddress2: TypeInputParts = PartsHelper.createInputParts("マンション・建物名など", "address2", InputEventType.ZENKAKU, false, "(例)xxxxxビル", 40)

    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "", 100, undefined, "", "text", this.email, "", true)
    inputPartsPassword1: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, true, "", 20, undefined, "", "password")
    inputPartsPassword2: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, true, "", 20, undefined, "", "password")

    selectSex: TypeSelectParts = PartsHelper.createSelectParts("性別", "text", Sex.getSelectList(), true)
    selectBirthDay: TypeSelectBirthDayParts = PartsHelper.createBirthdayParts("生年月日", "birthday")

    // HACK 必要であれば後にパーツ化
    agreementCheck: boolean = false
    agreementCheckError: string = ""
    isOpenAgreementModal: boolean = false

    agreementDecorteCheck: boolean = false
    agreementDecorteCheckError: string = ""
    isOpenAgreementDecorteModal: boolean = false

    agreementPrivacyPolicyCheck: boolean = false
    agreementPrivacyPolicyCheckError: string = ""
    isOpenAgreementPrivacyPolicyModal: boolean = false

    mailMagazineCheck: boolean = true
    
    // method
    async confirm() {
        this.isCheck = this.beforeCheck()
        
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }

        this.request = new CustomerSignupRequest(
            this.authKey,
            this.email,
            this.inputPartsPassword1.value,
            this.provisionShopCode,
            this.inputPartsLastName.value,
            this.inputPartsFirstName.value,
            this.inputPartsLastNameKana.value,
            this.inputPartsFirstNameKana.value,
            this.inputPartsTel.value,
            this.inputPartsZipCode.value,
            this.selectPreficture.value,
            this.inputPartsCity.value,
            this.inputPartsAddress1.value,
            this.inputPartsAddress2.value,
            this.selectSex.value,
            this.selectBirthDay.yyyy + this.selectBirthDay.mm + this.selectBirthDay.dd,
            true,
            {mail_delivery_flag : this.mailMagazineCheck}
        )
        VueHelper.lock()
        const response: any = await this.$api.postCustomerSignup(this.request);
        VueHelper.unlock()
        const apiResult = ErrorHelper.apiErrorHandler(
            response,
            this,
            [
                this.inputPartsPassword1,
                this.inputPartsLastName,
                this.inputPartsFirstName,
                this.inputPartsLastNameKana,
                this.inputPartsFirstNameKana,
                this.inputPartsTel,
                this.inputPartsZipCode,
                this.selectPreficture,
                this.inputPartsCity,
                this.inputPartsAddress1,
                this.inputPartsAddress2,
                this.selectSex,
                this.selectBirthDay
            ]
        )
        if (apiResult) {
            this.isConfirm = true
        } else {
            this.isCheck = false
            VueHelper.scrollTop()
            if (response.status === HttpStatus.BAD_REQUEST.code
                && response.data.type == Const.KEY_RESPONSE_BAD_REQUEST_TYPE_AUTH_KEY) {
                VueHelper.move(this, '/error')
            }
        }
    }

    back () {
        VueHelper.scrollTop()
        this.isConfirm = false
    }

    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsLastName),
            ValidationHelper.requiredInputParts(this.inputPartsFirstName),
            ValidationHelper.requiredInputParts(this.inputPartsLastNameKana),
            ValidationHelper.requiredInputParts(this.inputPartsFirstNameKana),
            ValidationHelper.requiredInputParts(this.inputPartsTel),
            ValidationHelper.requiredInputParts(this.inputPartsZipCode),
            ValidationHelper.requiredSelectParts(this.selectPreficture),
            ValidationHelper.requiredInputParts(this.inputPartsCity),
            ValidationHelper.requiredInputParts(this.inputPartsAddress1),
            ValidationHelper.requiredInputParts(this.inputPartsPassword1),
            this.checkSamePassword(this.inputPartsPassword1, this.inputPartsPassword2),
            ValidationHelper.requiredSelectParts(this.selectSex),
            ValidationHelper.requiredSelectBirthdayParts(this.selectBirthDay),
            this.checkAgreement(),
            this.checkAgreementDecorte(),
            this.checkAgreementPrivacyPolicy()
        ]
        
        return checkList.every(value => value === true)
    }

    openAgreementModal () {
        this.isOpenAgreementModal = true
    }

    closeAgreementModal () {
        this.isOpenAgreementModal = false
    }

    checkAgreement () {
        this.agreementCheckError = this.agreementCheck ? '' : '会員規約に同意いただけない場合は、会員登録ができません。'
        return this.agreementCheck
    }

    openAgreementDecorteModal () {
        this.isOpenAgreementDecorteModal = true
    }

    closeAgreementDecorteModal () {
        this.isOpenAgreementDecorteModal = false
    }

    checkAgreementDecorte () {
        this.agreementDecorteCheckError = this.agreementDecorteCheck ? '' : '利用規約に同意いただけない場合は、会員登録ができません。'
        return this.agreementDecorteCheck
    }

    openAgreementPrivacyPolicyModal () {
        this.isOpenAgreementPrivacyPolicyModal = true
    }

    closeAgreementPrivacyPolicyModal () {
        this.isOpenAgreementPrivacyPolicyModal = false
    }

    checkAgreementPrivacyPolicy () {
        this.agreementPrivacyPolicyCheckError = this.agreementPrivacyPolicyCheck ? '' : 'プライバシーポリシーに同意いただけない場合は、会員登録ができません。'
        return this.agreementPrivacyPolicyCheck
    }

    checkSamePassword (password1: TypeInputParts, password2: TypeInputParts) {
        // 既にエラーがある場合return
        if (password1.error) {
            return true
        }
        const check: boolean = password1.value === password2.value
        if (!check) {
            password1.error = '入力されたパスワードと確認用パスワードが一致しません'
        }
        return check
    }

    async searchAddress () {
        const zipCode = this.inputPartsZipCode.value
        if (ValidationHelper.is_len_eq(zipCode, 7)) {
            const response: any = await this.$api.searchAddress(zipCode);
            if (response.data.status == HttpStatus.OK.code && response.data.results) {
                const result = response.data.results[0]
                if (result.address1) {
                    this.selectPreficture.value = StringHelper.convertZenkaku(result.address1)
                    if (result.address2) {
                        this.inputPartsCity.value = StringHelper.convertZenkaku(result.address2)
                        if (result.address3) {
                            this.inputPartsCity.value += StringHelper.convertZenkaku(result.address3)
                        }
                    }
                }
            }
        }
    }

    // emit 
    inputLastName (value: string) {
        this.inputPartsLastName.value = value
    }
    inputFirstName (value: string) {
        this.inputPartsFirstName.value = value
    }
    inputLastNameKana (value: string) {
        this.inputPartsLastNameKana.value = value
    }
    inputFirstNameKana (value: string) {
        this.inputPartsFirstNameKana.value = value
    }
    inputPassword1 (value: string) {
        this.inputPartsPassword1.value = value
    }
    inputPassword2 (value: string) {
        this.inputPartsPassword2.value = value
    }
    changeAgreementCheck(value: boolean) {
        this.agreementCheck = value
    }
    changeAgreementDecorteCheck(value: boolean) {
        this.agreementDecorteCheck = value
    }
    changeMailMagazineCheck(value: boolean) {
        this.mailMagazineCheck = value
    }
    changePrivacyPolicyCheck(value: boolean) {
        this.agreementPrivacyPolicyCheck = value
    }
}
