











import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultEditComplete from '@/components/organisms/default/edit/DefaultEditComplete.vue';
import PRS0000001EditComplete from '@/components/organisms/PRS0000001/edit/PRS0000001EditComplete.vue';
import { VueHelper } from '@/helpers/vue';
import { AllowSettings } from '@/types/converter/convert';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultEditComplete,
        PRS0000001EditComplete,
        PRS0000001SubFooter
    }
})
export default class EditComplete extends Vue {

    private isLoading: boolean = true
    @Prop() private provisionShopCode! : string;

    mounted () {
        if (!AllowSettings.isUseEdit(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        this.isLoading = false
    }

}
