










import { Component, Vue } from 'vue-property-decorator';
import DefaultEmailComplete from '@/components/organisms/default/signup/DefaultEmailComplete.vue';
import PRS0000001EmailComplete from '@/components/organisms/PRS0000001/signup/PRS0000001EmailComplete.vue';
import { AllowSettings } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultEmailComplete,
        PRS0000001EmailComplete,
        PRS0000001SubFooter
    }
})
export default class EmailComplete extends Vue {

    private provisionShopCode: string = "";

    mounted () {
        this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')

        if (!AllowSettings.isUseSignup(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
    }

}
