












import { Component, Prop, Vue } from 'vue-property-decorator'
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import { TypeInputParts } from '@/types/components/types-parts';
@Component({
    components: {
        H2,
        InputBase
    },
})
export default class InputParts extends Vue {
    @Prop({ default: undefined }) private inputParts!: TypeInputParts;

    inputValue (value: string) {
        this.inputParts.value = value
    }

    change (value: string) {
        this.$emit('change', value)
    }
}
