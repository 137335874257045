import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import DefaultLayout from '@/components/layouts/Default.vue'
import NotFound from '../views/error/NotFound.vue'
import Error from '../views/error/Error.vue'
import EmailConfirmation from '../views/signup/EmailConfirmation.vue'
import EmailComplete from '../views/signup/EmailComplete.vue'
import Signup from '../views/signup/Signup.vue'
import SignupComplete from '../views/signup/SignupComplete.vue'
import Login from '../views/login/Login.vue'
import EditInput from '../views/edit/EditInput.vue'
import EditComplete from '../views/edit/EditComplete.vue'
import PasswordEditInput from '../views/password_edit/PasswordEditInput.vue'
import PasswordEditComplete from '../views/password_edit/PasswordEditComplete.vue'
import MailEditInput from '../views/mail_edit/MailEditInput.vue'
import MailEditSend from '../views/mail_edit/MailEditSend.vue'
import MailEditComplete from '../views/mail_edit/MailEditComplete.vue'
import WithdrawInput from '../views/withdraw/WithdrawInput.vue'
import WithdrawComplete from '../views/withdraw/WithdrawComplete.vue'
import PasswordRecoveryEmailConfirmation from '../views/password_recovery/PasswordRecoveryEmailConfirmation.vue'
import PasswordRecoveryEmailComplete from '../views/password_recovery/PasswordRecoveryEmailComplete.vue'
import PasswordRecoveryInput from '../views/password_recovery/PasswordRecoveryInput.vue'
import PasswordRecoveryComplete from '../views/password_recovery/PasswordRecoveryComplete.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/404',
        component: DefaultLayout,
        children: [
            {
                path: '/email-confirmation',
                name: 'EmailConfirmation',
                component: EmailConfirmation
            },
            {
                path: '/email-complete',
                name: 'EmailComplete',
                component: EmailComplete
            },
            {
                path: '/signup',
                name: 'Signup',
                component: Signup
            },
            {
                path: '/login',
                name: 'Login',
                component: Login
            },
            {
                path: '/signup/complete',
                name: 'SignupComplete',
                component: SignupComplete
            },
            {
                path: '/password-recovery',
                name: 'PasswordRecoveryEmailConfirmation',
                component: PasswordRecoveryEmailConfirmation
            },
            {
                path: '/password-recovery/email-complete',
                name: 'PasswordRecoveryEmailComplete',
                component: PasswordRecoveryEmailComplete
            },
            {
                path: '/password-recovery/input',
                name: 'PasswordRecoveryInput',
                component: PasswordRecoveryInput
            },
            {
                path: '/password-recovery/complete',
                name: 'PasswordRecoveryComplete',
                component: PasswordRecoveryComplete
            },
            {
                path: '/edit/:provisionShopCode',
                name: 'EditInput',
                component: EditInput,
                props: true
            },
            {
                path: '/edit/complete/:provisionShopCode',
                name: 'EditComplete',
                component: EditComplete,
                props: true
            },
            {
                path: '/password-edit/input/:provisionShopCode',
                name: 'PasswordEditInput',
                component: PasswordEditInput,
                props: true
            },
            {
                path: '/password-edit/complete/:provisionShopCode',
                name: 'PasswordEditComplete',
                component: PasswordEditComplete,
                props: true
            },
            {
                path: '/mail-edit/input/:provisionShopCode',
                name: 'MailEditInput',
                component: MailEditInput,
                props: true
            },
            {
                path: '/mail-edit/send/:provisionShopCode',
                name: 'MailEditSend',
                component: MailEditSend,
                props: true
            },
            {
                path: '/mail-edit/complete/:provisionShopCode',
                name: 'MailEditComplete',
                component: MailEditComplete,
                props: true
            },
            {
                path: '/withdraw/input/:provisionShopCode',
                name: 'WithdrawInput',
                component: WithdrawInput,
                props: true
            },
            {
                path: '/withdraw/complete/:provisionShopCode',
                name: 'WithdrawComplete',
                component: WithdrawComplete,
                props: true
            },
            {
                path: '/error',
                name: 'Error',
                component: Error
            },
            {
                path: '*',
                name: 'NotFound',
                component: NotFound
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
