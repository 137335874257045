












import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { VueHelper } from '@/helpers/vue';
import { KoseIDSite } from '@/types/converter/convert';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts
    },
})
export default class DefaultPasswordRecoveryEmailComplete extends Vue {
    @Prop() private provisionShopCode!: string;
    close () {
        window.close()
    }
    backToppage() {
        VueHelper.backToppage(this, this.provisionShopCode)
    }

    get isAnotherTabSSOSite (): boolean {
        return KoseIDSite.isAnotherTabSSOSite(this.provisionShopCode)
    }

    get isAnotherTabSite (): boolean {
        return KoseIDSite.isAnotherTabSite(this.provisionShopCode)
    }
}
