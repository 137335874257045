











import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import { VueHelper } from '@/helpers/vue';
import { KoseIDSite } from '@/types/converter/convert';
import { GetAuthKeyRedirect } from '@/types/api/types-api';
import { HttpStatus } from '@/types/converter/convert';
import { ErrorHelper } from '@/helpers/error';

@Component({
    components: {
        Title,
        BtnBase,
        InputParts
    },
})
export default class DefaultPasswordRecoveryEmailComplete extends Vue {
    @Prop() private provisionShopCode!: string;
    @Prop() private authKey!: string;
    
    close () {
        window.close()
    }

    back () {
        VueHelper.historyBack(this)
    }

    async backLogin() {
        VueHelper.lock()
        const request: GetAuthKeyRedirect = new GetAuthKeyRedirect(
            this.provisionShopCode,
            this.authKey
        )
        const response: any = await this.$api.getAuthKeyRedirect(request);
        if (response.status == HttpStatus.FOUND.code) {
            ErrorHelper.apiErrorHandler(response, this)
        } else {
            VueHelper.transitionByLocation(this,{ path: '/login', query: { provision_shop_code: this.provisionShopCode }})
        }
        VueHelper.unlock()
    }

    get isAnotherTabSite (): boolean {
        return KoseIDSite.isAnotherTabSite(this.provisionShopCode)
    }
}
