


















import { Component, Vue, Prop } from 'vue-property-decorator';
import InputParts from '@/components/molecules/InputParts.vue';
import Kanban from '@/components/atoms/Kanban.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';

@Component({
    components: {
        Kanban,
        BtnBase,
        InputParts
    },
})
export default class PRS0000001MailEditSend extends Vue {

    @Prop() private provisionShopCode!: string;

    
}
