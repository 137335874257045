












































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import TriangleLink from '@/components/atoms/TriangleLink.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import InputParts from '@/components/molecules/InputParts.vue';
import { TypeInputParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { HttpStatus, InputEventType } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';
import { ValidationHelper } from '@/helpers/validate';
import { PostLoginRequest } from '@/types/api/types-api';
import CheckBoxBase from '@/components/atoms/CheckBoxBase.vue';
import AgreementModal from '@/components/molecules/modal/AgreementModal.vue';
import AgreementDecorteModal from '@/components/molecules/modal/AgreementDecorteModal.vue';

@Component({
    components: {
        Title,
        BtnBase,
        H2,
        InputBase,
        InputParts,
        TriangleLink,
        CheckBoxBase,
        AgreementModal,
        AgreementDecorteModal
    },
})
export default class DefaultLogin extends Vue {

    @Prop() private provisionShopCode!: string;
    @Prop() private backUrlCode!: string;
    @Prop() private backUrlPrm!: string;
    isCheck: boolean = true
    isError: boolean = false
    isAgreeCheck: boolean = false
    isAgreeCheckBefore: boolean = true
    // Configure Input Email
    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, false, "(例)xxxxx@xxxxx.xxxxx", 100, undefined, "", "text")
    // Configure Input Password
    inputPartsPassword: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, false, "", 20, undefined, "", "password")

    // HACK 必要であれば後にパーツ化
    needAgreementCheck: boolean = false
    agreementCheck: boolean = false
    agreementCheckError: string = ""
    isOpenAgreementModal: boolean = false

    needAgreementBySiteCheck: boolean = false
    agreementDecorteCheck: boolean = false
    agreementDecorteCheckError: string = ""
    isOpenAgreementDecorteModal: boolean = false

    // VueUser
    fullName = ""
    email = ""
    address = ""
    tel = ""

    // Check Validation
    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsEmail),
            ValidationHelper.requiredInputParts(this.inputPartsPassword),
        ]
        return checkList.every(value => value === true)
    }

    beforeAgree() {
        const checkList: boolean[] = [
            this.checkAgreement(),
            this.checkAgreementDecorte()
        ]
        return checkList.every(value => value === true)
    }

    checkAgreementDecorte () {
        if (!this.needAgreementBySiteCheck) {
            return true
        }
        this.agreementDecorteCheckError = this.agreementDecorteCheck ? '' : '利用規約に同意いただけない場合は、会員登録ができません。'
        return this.agreementDecorteCheck
    }

    checkAgreement () {
        if (!this.needAgreementCheck) {
            return true
        }
        this.agreementCheckError = this.agreementCheck ? '' : '会員規約に同意いただけない場合は、会員登録ができません。'
        return this.agreementCheck
    }

    // Action signin
    async confirm() {
        // Validation Check
        this.isError = false
        this.isCheck = this.beforeCheck()
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }
        if (this.isAgreeCheck) {
            this.isAgreeCheckBefore = this.beforeAgree()
            if (!this.isAgreeCheckBefore) {
                VueHelper.scrollTop()
                return
            }
        }
        // Make Request
        const request = new PostLoginRequest (this.inputPartsEmail.value, this.inputPartsPassword.value, this.provisionShopCode, this.isAgreeCheck, this.backUrlCode, encodeURIComponent(this.backUrlPrm), false)
        // Call API
        VueHelper.lock()
        const response: any = await this.$api.postLogin(request);
        VueHelper.unlock()
        // API Resuponse Handle
        if (response.status === HttpStatus.LOCKED.code) {
            this.isAgreeCheck = true
            this.fullName = response.data.full_name_kanji
            this.email = response.data.email
            this.address = response.data.address
            this.tel = response.data.tel
            this.needAgreementBySiteCheck = response.data.is_mapping_first_login
            this.needAgreementCheck = response.data.is_old_agreement_check
        }
        else {
            if (!ErrorHelper.apiErrorHandler(
                response,
                this,
                [
                    this.inputPartsEmail,
                    this.inputPartsPassword,
                ]
            )) {
                VueHelper.scrollTop()
                this.isError = true
            }
        }
    }

    openAgreementDecorteModal () {
        this.isOpenAgreementDecorteModal = true
    }

    closeAgreementDecorteModal () {
        this.isOpenAgreementDecorteModal = false
    }

    openAgreementModal () {
        this.isOpenAgreementModal = true
    }

    closeAgreementModal () {
        this.isOpenAgreementModal = false
    }

    changeAgreementCheck(value: boolean) {
        this.agreementCheck = value
    }
    changeAgreementDecorteCheck(value: boolean) {
        this.agreementDecorteCheck = value
    }

    backLogin() {
        this.isAgreeCheck = false
    }
    
    // Action Press Back Button
    historyBack() {
        VueHelper.historyBack(this)
    }

    // Action Press Recovery Password
    movePasswordRecovery() {
        if (this.backUrlCode && this.backUrlPrm) {
            VueHelper.transitionByLocation(this, { path: '/password-recovery', query: {
                provision_shop_code: this.provisionShopCode,
                back_url_code:this.backUrlCode,
                back_url_prm:this.backUrlPrm,
            }})
        }
        else {
            VueHelper.transitionByLocation(this, { path: '/password-recovery', query: { provision_shop_code: this.provisionShopCode }})
        }
    }

    // Action Press For Beginners
    moveSignup() {
        if (this.backUrlCode && this.backUrlPrm) {
            VueHelper.transitionByLocation(this,{ path: '/email-confirmation', query: {
                provision_shop_code: this.provisionShopCode,
                back_url_code:this.backUrlCode,
                back_url_prm:this.backUrlPrm,
            }})
        }
        else {
            VueHelper.transitionByLocation(this,{ path: '/email-confirmation', query: { provision_shop_code: this.provisionShopCode }})
        }
    }
    
}
