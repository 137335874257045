





















import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultWithdrawInput from '@/components/organisms/default/withdraw/DefaultWithdrawInput.vue';
import PRS0000001WithdrawInput from '@/components/organisms/PRS0000001/withdraw/PRS0000001WithdrawInput.vue';
import { VueHelper } from '@/helpers/vue';
import { AllowSettings, LoginBackCode } from '@/types/converter/convert';
import { GetCustomerEditRequest } from '@/types/api/types-api';
import { ErrorHelper } from '@/helpers/error';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultWithdrawInput,
        PRS0000001WithdrawInput,
        PRS0000001SubFooter
    },
})
export default class WithdrawInput extends Vue {

    private isLoading: boolean = true
    @Prop() private provisionShopCode!: string;
    private customerResponse: any;
    private isLogin: boolean = false

    async created () {
        //this.provisionShopCode = VueHelper.getParameter(this, 'provision_shop_code')
        if (!AllowSettings.isUseWithdraw(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        const request: GetCustomerEditRequest = new GetCustomerEditRequest(this.provisionShopCode)
        VueHelper.lock()
        const response: any = await this.$api.getCustomerEdit(request);
        if (ErrorHelper.apiErrorHandler(
            response,
            this,
            [],
            LoginBackCode.WITHDRAW.code
        )) {
            this.isLogin = true
        }
        VueHelper.unlock()
        this.customerResponse = response.data
        this.isLoading = false
    }

    linkLogin() {
        VueHelper.transitionByLocation(
            this,
            { path: '/login', query: { provision_shop_code: this.provisionShopCode, back_url_code: LoginBackCode.WITHDRAW.code }}
        )
    }
}
