























import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultEditInput from '@/components/organisms/default/edit/DefaultEditInput.vue';
import PRS0000001EditInput from '@/components/organisms/PRS0000001/edit/PRS0000001EditInput.vue';
import DefaultOLCEditInput from '@/components/organisms/default/edit/DefaultOLCEditInput.vue';
import { VueHelper } from '@/helpers/vue';
import { AllowSettings, LoginBackCode, KoseIDSite } from '@/types/converter/convert';
import { GetCustomerEditRequest } from '@/types/api/types-api';
import { ErrorHelper } from '@/helpers/error';
import PRS0000001SubFooter from '@/components/organisms/PRS0000001/footer/PRS0000001SubFooter.vue';

@Component({
    components: {
        DefaultEditInput,
        PRS0000001EditInput,
        DefaultOLCEditInput,
        PRS0000001SubFooter
    },
})
export default class EditInput extends Vue {
    
    private isLoading: boolean = true
    @Prop() private provisionShopCode!: string;
    private customerResponse: any;
    private isLogin: boolean = false

    async created () {
        if (!AllowSettings.isUseEdit(this.provisionShopCode)) {
            VueHelper.move(this, '/404')
        }
        const request: GetCustomerEditRequest = new GetCustomerEditRequest(this.provisionShopCode)
        VueHelper.lock()
        const response: any = await this.$api.getCustomerEdit(request);
        if (ErrorHelper.apiErrorHandler(
            response,
            this,
            [],
            LoginBackCode.EDIT.code
        )) {
            this.isLogin = true
            this.customerResponse = response.data
        }
        VueHelper.unlock()
        this.isLoading = false
    }

    linkLogin() {
        VueHelper.transitionByLocation(
            this,
            { path: '/login', query: { provision_shop_code: this.provisionShopCode, back_url_code: LoginBackCode.EDIT.code }}
        )
    }

    get isOLCDecorte () {
        return KoseIDSite.isOLCDecorteGroup(this.provisionShopCode)
    }

    get isOLCJill () {
        return KoseIDSite.isOLCJillGroup(this.provisionShopCode)
    }

    get isMaisonJill () {
        return KoseIDSite.isOLCMaisonGroup(this.provisionShopCode)
    }

}
