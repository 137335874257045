





import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ProgressCircular extends Vue {
    @Prop({ default: true }) private isActive!: boolean;
}
