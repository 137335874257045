






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Title extends Vue {
    @Prop({ default: '' }) private kanbanImg!: string;
    @Prop({ default: '' }) private label!: string;

}
