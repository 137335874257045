import { BackendAPIConfig, KoseSiteConfig } from '@/types/types'

export const env: string = 'staging'

export const backendAPIConfig: BackendAPIConfig = {
    endpoint: 'https://stg-membership.kose.co.jp/api'
}

export const OCLSiteConfig: KoseSiteConfig = {
    siteUrl: '',
    siteTop: 'https://maison.kose.co.jp/site/customer/menu.aspx',
    siteMyPage: '',
    withdrawTop: 'https://maison.kose.co.jp/site/customer/menu.aspx',
    moveRedirect: false,
    autLogin: false
}

export const PRSiteConfig: KoseSiteConfig = {
    siteUrl: 'https://p-t.kose.co.jp',
    siteTop: '',
    siteMyPage: '',
    withdrawTop: 'https://p-t.kose.co.jp/withdraw/complete',
    moveRedirect: true,
    autLogin: true
}

export const OLCDecorteSiteConfig: KoseSiteConfig = {
    siteUrl: 'https://stg-web-bc.olcstg.k-aws.jp',
    siteTop: 'https://stg-web-bc.olcstg.k-aws.jp/customer/top',
    siteMyPage: 'https://stg-web-bc.olcstg.k-aws.jp/customer/reservationList',
    withdrawTop: '',
    moveRedirect: false,
    autLogin: true
}

export const OLCJillSiteConfig: KoseSiteConfig = {
    siteUrl: 'https://stg-jill.olcstg.k-aws.jp',
    siteTop: 'https://stg-jill.olcstg.k-aws.jp/customer/course-select',
    siteMyPage: 'https://stg-jill.olcstg.k-aws.jp/customer/reserves',
    withdrawTop: '',
    moveRedirect: false,
    autLogin: true
}

export const OLCMaisonSiteConfig: KoseSiteConfig = {
    siteUrl: 'https://stg-maison.olcstg.k-aws.jp',
    siteTop: 'https://stg-maison.olcstg.k-aws.jp/customer/shop-select',
    siteMyPage: 'https://stg-maison.olcstg.k-aws.jp/customer/reserves',
    withdrawTop: '',
    moveRedirect: false,
    autLogin: true
}
