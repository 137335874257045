import { UiModule } from '@/store/ui'
import Vue from 'vue'
import { Location } from 'vue-router'
import { KoseIDSite } from '@/types/converter/convert';

export class VueHelper {
    public static getParameter (vue: Vue, param: string): string {
        const provisionShopCode = vue.$route.query[param] ? vue.$route.query[param] : ''
        return provisionShopCode.toString()
    }

    // no history
    public static move (vue: Vue, path: string) {
        VueHelper.scrollTop()
        vue.$router.replace(path)
    }

    public static transition (vue: Vue, path: string) {
        VueHelper.scrollTop()
        vue.$router.push(path)
    }

    public static transitionByLocation(vue: Vue, location:Location) {
        vue.$router.push(location)
    }

    // no history
    public static moveByLocation(vue: Vue, location:Location) {
        vue.$router.replace(location)
    }

    public static historyBack (vue: Vue) {
        vue.$router.go(-1)
    }

    public static backToppage (vue: Vue, provitionShopCode: string) {
        const site = KoseIDSite.findValue(provitionShopCode)
        if (!site) {
            VueHelper.move(vue, '/404')
        } else {
            window.location = site.config.siteTop
        }
    }

    public static moveMyPage (vue: Vue, provitionShopCode: string) {
        const site = KoseIDSite.findValue(provitionShopCode)
        if (!site) {
            VueHelper.move(vue, '/404')
        } else {
            if (site.config.siteMyPage) {
                window.location = site.config.siteMyPage
            } else {
                window.location = site.config.siteTop
            }
            
        }
    }

    public static moveShopPage(vue: Vue, provitionShopCode: string, uri: string) {
        const site = KoseIDSite.findValue(provitionShopCode)
        if (!site) {
            VueHelper.move(vue, '/404')
        } else {
            window.location.href = site.config.siteUrl + uri
        }
    }

    public static moveWithDraw (vue: Vue, provitionShopCode: string) {
        const site = KoseIDSite.findValue(provitionShopCode)
        if (!site) {
            VueHelper.move(vue, '/404')
        } else {
            if (site.config.moveRedirect) {
                window.location = site.config.withdrawTop
            } else {
                vue.$router.push({ path: `/withdraw/complete/${provitionShopCode}`})
            }
        }
    }

    public static scrollTop() {
        window.scrollTo({top: 0, behavior: 'auto'})
    }

    public static lock() {
        const elements = document.getElementsByTagName('button')
        for( let i = 0; i < elements.length; i++  ){
            elements[i].disabled = true
        }
        UiModule.updateProgress(true)
    }

    public static unlock() {
        const elements = document.getElementsByTagName('button')
        for( let i = 0; i < elements.length; i++  ){
            elements[i].disabled = false
        }
        setTimeout(function () {
            UiModule.updateProgress(false)
        }, 500)
    }
}