





































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Title from '@/components/atoms/Title.vue';
import BtnBase from '@/components/atoms/BtnBase.vue';
import TriangleLink from '@/components/atoms/TriangleLink.vue';
import H2 from '@/components/atoms/H2.vue';
import InputBase from '@/components/atoms/InputBase.vue';
import InputParts from '@/components/molecules/InputParts.vue';
import SelectBirthDayParts from '@/components/molecules/SelectBirthDayParts.vue';
import SelectParts from '@/components/molecules/SelectParts.vue';
import { Prefectures, Sex, LoginBackCode } from '@/types/converter/convert';
import { TypeInputParts, TypeSelectParts, TypeSelectBirthDayParts } from '@/types/components/types-parts';
import { PartsHelper } from '@/helpers/parts';
import { HttpStatus, InputEventType, KoseIDSite } from '@/types/converter/convert';
import { VueHelper } from '@/helpers/vue';
import { ErrorHelper } from '@/helpers/error';
import { ValidationHelper } from '@/helpers/validate';
import { PutCustomerEdit } from '@/types/api/types-api';
import DefaultEditConfirmation from '@/components/organisms/default/edit/DefaultEditConfirmation.vue';

@Component({
    components: {
        Title,
        BtnBase,
        H2,
        InputBase,
        InputParts,
        TriangleLink,
        SelectBirthDayParts,
        SelectParts,
        DefaultEditConfirmation
    },
})
export default class DefaultEditInput extends Vue {

    @Prop() private provisionShopCode!: string
    @Prop() private customerResponse: any
    isCheck: boolean = true
    isConfirm: boolean = false
    request: PutCustomerEdit | null = null
    // Configure Input
    inputPartsLastName: TypeInputParts = PartsHelper.createInputParts("お名前(姓)", "last_name_kanji", InputEventType.ZENKAKU, true, "(例)xxxxx", 10)
    inputPartsFirstName: TypeInputParts = PartsHelper.createInputParts("お名前(名)", "first_name_kanji", InputEventType.ZENKAKU, true, "(例)xxxxx", 10)
    inputPartsLastNameKana: TypeInputParts = PartsHelper.createInputParts("フリガナ(セイ)", "last_name_kana", InputEventType.ZENKAKU, true, "(例)xxxxx", 20)
    inputPartsFirstNameKana: TypeInputParts = PartsHelper.createInputParts("フリガナ(メイ)", "first_name_kana", InputEventType.ZENKAKU, true, "(例)xxxxx", 20)
    inputPartsTel: TypeInputParts = PartsHelper.createInputParts("電話番号", "tel", InputEventType.HANKAKU, true, "(例)03xxxxxxxx", 11, 18, "※ハイフンなしで入力してください")
    inputPartsZipCode: TypeInputParts = PartsHelper.createInputParts("郵便番号", "zip_code", InputEventType.HANKAKU, true, "(例)1234567", 7, 18, "※ハイフンなしで入力してください")
    selectPreficture: TypeSelectParts = PartsHelper.createSelectParts("都道府県", "state", Prefectures.getSelectList(), true)
    inputPartsCity: TypeInputParts = PartsHelper.createInputParts("市区町村", "city", InputEventType.ZENKAKU, true, "(例)xxxxx区", 40, 18)
    inputPartsAddress1: TypeInputParts = PartsHelper.createInputParts("丁目・番地", "address1", InputEventType.ZENKAKU, true, "(例)xxxxx", 40)
    inputPartsAddress2: TypeInputParts = PartsHelper.createInputParts("マンション・建物名など", "address2", InputEventType.ZENKAKU, false, "(例)xxxxxビル", 40)
    inputPartsEmail: TypeInputParts = PartsHelper.createInputParts("メールアドレス", "email", InputEventType.NONE, true, "", 100, undefined, "", "text", "", "", true)
    inputPartsPassword1: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, true, "", 20, undefined, "", "password")
    inputPartsPassword2: TypeInputParts = PartsHelper.createInputParts("パスワード", "password", InputEventType.NONE, true, "", 20, undefined, "", "password")
    selectSex: TypeSelectParts = PartsHelper.createSelectParts("性別", "text", Sex.getSelectList(), true)
    selectBirthDay: TypeSelectBirthDayParts = PartsHelper.createBirthdayParts("生年月日", "birthday")

    created () {
        this.inputPartsEmail.value = this.customerResponse.email
        this.inputPartsLastName.value = this.customerResponse.last_name_kanji
        this.inputPartsFirstName.value = this.customerResponse.first_name_kanji
        this.inputPartsLastNameKana.value = this.customerResponse.last_name_kana
        this.inputPartsFirstNameKana.value = this.customerResponse.first_name_kana
        this.inputPartsTel.value = this.customerResponse.tel
        this.inputPartsZipCode.value = this.customerResponse.zip_code
        this.selectPreficture.value = this.customerResponse.state
        this.inputPartsCity.value = this.customerResponse.city
        this.inputPartsAddress1.value = this.customerResponse.address1
        this.inputPartsAddress2.value = this.customerResponse.address2
        if (this.customerResponse.sex) {
            this.selectSex.value =this.customerResponse.sex
        }
        if (this.customerResponse.birthday) {
            this.selectBirthDay.yyyy = this.customerResponse.birthday.substr(0, 4)
            this.selectBirthDay.mm = this.customerResponse.birthday.substr(4, 2)
            this.selectBirthDay.dd =  this.customerResponse.birthday.substr(6, 2)
        }
    }

    // Check Validation
    beforeCheck (): boolean {
        const checkList: boolean[] = [
            ValidationHelper.requiredInputParts(this.inputPartsLastName),
            ValidationHelper.requiredInputParts(this.inputPartsFirstName),
            ValidationHelper.requiredInputParts(this.inputPartsLastNameKana),
            ValidationHelper.requiredInputParts(this.inputPartsFirstNameKana),
            ValidationHelper.requiredInputParts(this.inputPartsTel),
            ValidationHelper.requiredInputParts(this.inputPartsZipCode),
            ValidationHelper.requiredSelectParts(this.selectPreficture),
            ValidationHelper.requiredInputParts(this.inputPartsCity),
            ValidationHelper.requiredInputParts(this.inputPartsAddress1),
            ValidationHelper.requiredSelectParts(this.selectSex),
            ValidationHelper.requiredSelectBirthdayParts(this.selectBirthDay),
        ]
        return checkList.every(value => value === true)
    }

    // Action signin
    async confirm() {
        // Validation Check
        this.isCheck = this.beforeCheck()
        if (!this.isCheck) {
            VueHelper.scrollTop()
            return
        }
        // Request API
        this.request = new PutCustomerEdit (
            this.provisionShopCode,
            this.inputPartsLastName.value,
            this.inputPartsFirstName.value,
            this.inputPartsLastNameKana.value,
            this.inputPartsFirstNameKana.value,
            this.inputPartsTel.value,
            this.inputPartsZipCode.value,
            this.selectPreficture.value,
            this.inputPartsCity.value,
            this.inputPartsAddress1.value,
            this.inputPartsAddress2.value,
            this.selectSex.value,
            this.selectBirthDay.yyyy + this.selectBirthDay.mm + this.selectBirthDay.dd,
            true,
            this.provisionShopCode
        )
        // Call APIx
        VueHelper.lock()
        const response: any = await this.$api.putCustomerEdit(this.request)
        VueHelper.unlock()
        VueHelper.scrollTop()
        if (response.status === HttpStatus.OK.code) {
            this.isConfirm = true
        }
        else {
            this.isCheck = false
            VueHelper.scrollTop()
            ErrorHelper.apiErrorHandler(
                response,
                this,
                [
                    this.inputPartsPassword1,
                    this.inputPartsLastName,
                    this.inputPartsFirstName,
                    this.inputPartsLastNameKana,
                    this.inputPartsFirstNameKana,
                    this.inputPartsTel,
                    this.inputPartsZipCode,
                    this.selectPreficture,
                    this.inputPartsCity,
                    this.inputPartsAddress1,
                    this.inputPartsAddress2,
                    this.selectSex,
                    this.selectBirthDay
                ],
                LoginBackCode.EDIT.code
            )
        }
        
    }
    
    // Action Press Back Button
    backToppage() {
        VueHelper.historyBack(this)
    }

    back () {
        VueHelper.scrollTop()
        this.isConfirm = false
    }

    // Action Press Recovery Password
    movePasswordEdit() {
        VueHelper.transitionByLocation(this, { path: `/password-edit/input/${this.provisionShopCode}`})
    }

    moveMailEdit() {
        VueHelper.transitionByLocation(this, { path: `/mail-edit/input/${this.provisionShopCode}`})
    }

    // Action Press For Beginners
    moveWithdraw() {
        VueHelper.transitionByLocation(this,{ path: `/withdraw/input/${this.provisionShopCode}`})
    }

    // emit 
    inputLastName (value: string) {
        this.inputPartsLastName.value = value
    }
    inputFirstName (value: string) {
        this.inputPartsFirstName.value = value
    }
    inputLastNameKana (value: string) {
        this.inputPartsLastNameKana.value = value
    }
    inputFirstNameKana (value: string) {
        this.inputPartsFirstNameKana.value = value
    }

    get isAnotherTabSSOSite (): boolean {
        return KoseIDSite.isAnotherTabSSOSite(this.provisionShopCode)
    }
    
}
