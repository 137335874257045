















import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectBase from '../atoms/SelectBase.vue'
import H2 from '../atoms/H2.vue'
import { TypeSelectBirthDayParts } from '@/types/components/types-parts';
import { DateHelper } from '@/helpers/date';

@Component({
    components: {
        SelectBase,
        H2
    }
})
export default class SelectBirthDayParts extends Vue {
    @Prop({ default: undefined }) private selectParts!: TypeSelectBirthDayParts;


    selectYear (value: string) {
        this.selectParts.yyyy = value
    }
    
    selectMonth (value: string) {
        this.selectParts.mm = value
    }

    selectDay (value: string) {
        this.selectParts.dd = value
    }

    getYearList () {
        const now: Date = DateHelper.getNow()
        let result = []
        const to = now.getFullYear()
        for (let i = 1900; i <= to; i++) {
            result.push({
                label: String(i),
                val: String(i)
            })
        }
        return result
    }

    getMonthList () {
        let result = []
        for (let i = 1; i <= 12; i++) {
            let val: string = i < 10 ? '0' + String(i) : String(i)
            result.push({
                label: String(i),
                val: val
            })
        }
        return result
    }

    getDayList () {
        let result = []
        for (let i = 1; i <= 31; i++) {
            let val: string = i < 10 ? '0' + String(i) : String(i)
            result.push({
                label: String(i),
                val: val
            })
        }
        return result
    }
}
