





import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component
export default class TriangleLink extends Vue {
    @Prop({ default: '' }) private label!: string;
    @Emit('onClick')
    onClick () {
        // do nothing
    }
}
